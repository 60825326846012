import React, { FC } from 'react';

import { useAppDispatch, AppDispatch } from 'src/store';
import { PopupManager } from 'src/store/src/popup/popup/PopupManager';
import { PopupFormBehavior } from 'src/data/popupFormTypes';
import { PopupFormProps } from './types';
import { setSpinner } from 'src/store/src/general/system/systemSlice';
import { useLoadPopupFormFields } from 'src/hooks/src/shared/useLoadPopupFormFields';

export const PopupForm: FC<PopupFormProps> = ({ children, action, additional }) => {
    const dispatch: AppDispatch = useAppDispatch();
    const behaviour = action.behaviour as PopupFormBehavior;

    const loadPopupFormData = useLoadPopupFormFields();

    const handleSetPopupForm = async () => {
        if (additional.listCellRowId) {
            const response = await loadPopupFormData(behaviour.data.actionId);
            if (!response.success) return;

            new PopupManager({ dispatch, action }).setPopupDynamic({
                rowsId: [additional.listCellRowId],
                title: behaviour.data.title,
                sourceOfTrigger: {
                    type: 'object',
                    typeWew: additional.typeSection
                },
                additional,
                popupFormData: {
                    ...behaviour.data,
                    fields: response.data
                }
            });

            dispatch(setSpinner(false));
        } else {
            new PopupManager({ dispatch, action }).setPopupInfo(
                'Brak wierszy spełniajacych kryteria'
            );
        }
    };

    return <div onClick={() => handleSetPopupForm()}>{children}</div>;
};
