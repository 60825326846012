import React, { FC } from 'react';
import ButtonCustom from 'src/components/shared/atoms/button/Button';
import { ApiActionSaveMassEditObjectsProps } from './types';
import { useSubmitMassRejectEditObjects } from 'src/hooks/src/massEditObjectsScreen/useSubmitMassRejectEditObjects';

const ApiActionRejectSaveMassEditObjects: FC<ApiActionSaveMassEditObjectsProps> = ({
    children
}) => {
    const onClick = useSubmitMassRejectEditObjects();
    return (
        <ButtonCustom onClick={onClick} withoutStyles={true}>
            {children}
        </ButtonCustom>
    );
};

export default ApiActionRejectSaveMassEditObjects;
