import {
    FieldDependentDefaultManager,
    FieldDependentDefaultManagerProps
} from 'src/components/popupFormExtended/fieldGlobalDependent/fieldsDependentManager/fieldDependentDefaultManager/FieldDependentDefaultManager';
import { FieldCheckbox } from 'src/components/popupFormExtended/dynamicField/fieldCheckbox/types';

type FieldDependentCheckboxManagerProps = Omit<
    FieldDependentDefaultManagerProps,
    'fieldWasTriggered'
> & {
    fieldWasTriggered: FieldCheckbox;
};

export class FieldDependentCheckboxManager extends FieldDependentDefaultManager {
    fieldWasTriggered: FieldCheckbox;

    constructor({
        formState,
        popupFormExtendedData,
        tempFieldsState,
        fieldWasTriggered,
        dataFunctionDynamic,
        relationId,
        prefix
    }: FieldDependentCheckboxManagerProps) {
        super({
            formState,
            popupFormExtendedData,
            tempFieldsState,
            fieldWasTriggered: fieldWasTriggered,
            dataFunctionDynamic,
            relationId,
            prefix
        });
        this.fieldWasTriggered = fieldWasTriggered;
    }

    async zmienWartoscPola() {
        if (!this.fieldWasTriggered.isUpdatedDepedententDefault) {
            await this.checkAndChangeFieldChangeValueStateOfField();
            await this.runNested(this.fieldWasTriggeredDynamicFieldT);
        }
    }

    private async checkAndChangeFieldChangeValueStateOfField() {
        const oldVal = this.fieldWasTriggered.value ? '1' : '0';
        let val = this.fieldWasTriggered.value ? '1' : '0';

        const value = await this.getValueToSetForChangeValueField();
        val = value ? value : '0';

        const newValue = {
            ...this.fieldWasTriggered,
            value: val === '1'
        };
        if (oldVal !== val) {
            this.setTempFieldsState(newValue, 'isUpdatedDepedententDefault');
            this.isRunNested = true;
        }
    }

    async przeliczWartosc() {
        if (!this.fieldWasTriggered.isUpdatedCalc) {
            await this.calc();
            await this.runNested(this.fieldWasTriggeredDynamicFieldT);
        }
    }

    private async calc() {
        const data = this.getValuesToSet();

        let newValue = false;
        if (
            data.one.checkbox &&
            data.two.checkbox &&
            this.fieldWasTriggeredDynamicFieldT?.wyliczenie_dzialanie === 'andcheck'
        )
            newValue = true;
        const value = {
            ...this.fieldWasTriggered,
            value: newValue
        };
        if (newValue) {
            this.setTempFieldsState(value, 'isUpdatedCalc');
            this.isRunNested = true;
        }
    }
}
