import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useRestrictforNotLogged } from 'src/hooks';
import {
    resetPageTitle as resetPageTitleFunction,
    updatePageTitleWithScreenName
} from 'src/store/src/general/system/systemSlice';

export const useRunAllHooksOnEachRenderReactPage = (pageTitle?: string) => {
    const dispatch = useDispatch();
    useRestrictforNotLogged();

    // clearing page title from screen name
    useEffect(() => {
        if (pageTitle) {
            dispatch(updatePageTitleWithScreenName(pageTitle));
        } else {
            dispatch(resetPageTitleFunction());
        }
    }, [dispatch, pageTitle]);
};
