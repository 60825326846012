import { SafeNavigateFunction } from 'src/hooks/src/shared/types';
import { BaseApiResponse, InternalApiResponse } from '../types';
import { getLegacyCompatibleUrlData, filterSafeParams } from 'src/utils';
import { simpleQueryAnyJson } from './simpleQueryAnyJson';

export enum InternalApiErrorCodes {
    JsonParseError = 500,
    Navigation = 600
}

/**
 * Fetches information from server
 */
export async function simpleQueryAPIData<T>(
    url: string,
    method: 'GET' | 'POST' | 'DELETE' = 'GET',
    bodyContent: any = undefined,
    parseBody: boolean = true,
    enableEffects: { alert: boolean; navigation: boolean } = { alert: true, navigation: true }, // enable to switch on off alerts and navigation
    navigate?: SafeNavigateFunction
): Promise<BaseApiResponse<T>> {
    const response = await simpleQueryAnyJson<InternalApiResponse<T>>(
        url,
        method,
        bodyContent,
        parseBody
    );

    if (!response.success) {
        return {
            success: false,
            errorCode: InternalApiErrorCodes.JsonParseError,
            data: null,
            message: response.error
        };
    }

    const dataParsed = response.data;

    if (enableEffects.alert && dataParsed.success === false && dataParsed.showAlert)
        alert(dataParsed.message);

    if (dataParsed.navigate === true) {
        if (enableEffects.navigation) {
            if (!navigate) {
                console.error(
                    "simpleQueryAPIData: api return navigate response, but func doesn't have nav function"
                );
            } else {
                const safeParams = filterSafeParams(
                    dataParsed.data.url,
                    new URLSearchParams(dataParsed.data.params)
                );
                const { url, queryParams } = getLegacyCompatibleUrlData(
                    dataParsed.data.url,
                    new URLSearchParams(safeParams)
                );
                navigate(
                    `${url}?${queryParams?.toString()}`,
                    undefined,
                    undefined,
                    undefined,
                    dataParsed.replace
                );
            }
        }
        return {
            success: false,
            errorCode: InternalApiErrorCodes.Navigation,
            data: null,
            message: 'Nawigacja z API'
        };
    }

    return dataParsed;
}
