import {
    FieldDependentDefaultManager,
    FieldDependentDefaultManagerProps
} from 'src/components/popupFormExtended/fieldGlobalDependent/fieldsDependentManager/fieldDependentDefaultManager/FieldDependentDefaultManager';
import { FieldDate } from 'src/components/popupFormExtended/dynamicField/fieldDate/types';
import { JS_TIMESTAMP_DAY } from 'src/constants/timestamps';
import { DateManager } from 'src/utils';
import { NumberManager } from 'src/utils/src/shared/NumberManager';

type FieldDependentDateManagerProps = Omit<
    FieldDependentDefaultManagerProps,
    'fieldWasTriggered'
> & {
    fieldWasTriggered: FieldDate;
};

export class FieldDependentDateManager extends FieldDependentDefaultManager {
    fieldWasTriggered: FieldDate;

    constructor({
        formState,
        popupFormExtendedData,
        tempFieldsState,
        fieldWasTriggered,
        dataFunctionDynamic,
        relationId,
        prefix
    }: FieldDependentDateManagerProps) {
        super({
            formState,
            popupFormExtendedData,
            tempFieldsState,
            fieldWasTriggered: fieldWasTriggered,
            dataFunctionDynamic,
            relationId,
            prefix
        });
        this.fieldWasTriggered = fieldWasTriggered;
    }

    async przeliczWartosc() {
        if (!this.fieldWasTriggered.isUpdatedCalc) {
            await this.calc();
            await this.runNested(this.fieldWasTriggeredDynamicFieldT);
        }
    }

    private async calc() {
        const data = this.getValuesToSet();
        let newValue: null | Date = null;
        if (this.fieldSuperiorOne?.typ === 'data' && !this.fieldSuperiorTwo) {
            newValue = new Date(data.one.timestampAll);
        } else if (this.fieldSuperiorOne?.typ === 'data_godzina' && !this.fieldSuperiorTwo) {
            newValue = new Date(data.one.timestampAll);
        } else if (
            this.fieldSuperiorOne?.typ === 'data' &&
            this.fieldSuperiorTwo?.typ === 'data_wzgledna'
        ) {
            if (this.fieldSuperiorOne.value && this.fieldSuperiorTwo.value.date) {
                switch (this.fieldSuperiorTwo.value.zakres) {
                    case 'm':
                        newValue = DateManager.changeMonthInDate(
                            this.fieldSuperiorOne.value,
                            Number(this.fieldSuperiorTwo.value.date),
                            this.fieldWasTriggeredDynamicFieldT?.wyliczenie_dzialanie ?? ''
                        );
                        break;
                    case 'r':
                        newValue = DateManager.changeYearInDate(
                            this.fieldSuperiorOne.value,
                            Number(this.fieldSuperiorTwo.value.date),
                            this.fieldWasTriggeredDynamicFieldT?.wyliczenie_dzialanie ?? ''
                        );
                        break;
                    case 'd':
                        switch (this.fieldWasTriggeredDynamicFieldT?.wyliczenie_dzialanie) {
                            case '+':
                                newValue = new Date(
                                    data.one.timestampWithoutHourMinute +
                                        Number(this.fieldSuperiorTwo.value.date) * JS_TIMESTAMP_DAY
                                );
                                break;
                            case '-':
                                newValue = new Date(
                                    data.one.timestampWithoutHourMinute -
                                        Number(this.fieldSuperiorTwo.value.date) * JS_TIMESTAMP_DAY
                                );
                                break;
                            default:
                                break;
                        }
                        break;
                    case 't':
                        switch (this.fieldWasTriggeredDynamicFieldT?.wyliczenie_dzialanie) {
                            case '+':
                                newValue = new Date(
                                    data.one.timestampWithoutHourMinute +
                                        Number(this.fieldSuperiorTwo.value.date) *
                                            JS_TIMESTAMP_DAY *
                                            7
                                );
                                break;
                            case '-':
                                newValue = new Date(
                                    data.one.timestampWithoutHourMinute -
                                        Number(this.fieldSuperiorTwo.value.date) *
                                            JS_TIMESTAMP_DAY *
                                            7
                                );
                                break;
                            default:
                                break;
                        }
                        break;
                    default:
                        break;
                }
            }
        } else if (
            this.fieldSuperiorOne?.typ === 'data' &&
            this.fieldSuperiorTwo?.typ === 'liczba'
        ) {
            if (this.fieldSuperiorOne.value) {
                const number = isNaN(data.two.number) ? 0 : data.two.number;
                newValue = new Date(
                    NumberManager.calcArithmeticValue(
                        data.one.timestampWithoutHourMinute,
                        number * JS_TIMESTAMP_DAY,
                        this.fieldWasTriggeredDynamicFieldT?.wyliczenie_dzialanie
                    )
                );
            }
        } else if (
            this.fieldSuperiorOne?.typ === 'data_godzina' &&
            this.fieldSuperiorTwo?.typ === 'liczba'
        ) {
            if (this.fieldSuperiorOne.value.date) {
                const number = isNaN(data.two.number) ? 0 : data.two.number;
                newValue = new Date(
                    NumberManager.calcArithmeticValue(
                        data.one.timestampWithoutHourMinute,
                        number * JS_TIMESTAMP_DAY,
                        this.fieldWasTriggeredDynamicFieldT?.wyliczenie_dzialanie
                    )
                );
            }
        }
        if (newValue) {
            const value = {
                ...this.fieldWasTriggered,
                value: newValue
            };
            this.setTempFieldsState(value, 'isUpdatedCalc');
            this.isRunNested = true;
        }
    }
}
