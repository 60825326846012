import { getFieldAdditionalData } from 'src/api/src/shared/getFieldAdditionalData';
import {
    DateManager,
    getValuesFromFields,
    getVatStake,
    safeGetArrayOptionsWithAddNewObjectOption,
    safeGetKeyValueOptionsWithAddNewObjectOption
} from 'src/utils';
import { Fields, FieldsPure } from 'src/components/popupFormExtended/dynamicField/types';
import { DynamicFormObjectDataConverter } from 'src/components/popupFormExtended/dynamicFormObject/DynamicFormObjectDataConverter';
import { FormState } from 'src/components/popupFormExtended/dynamicFormObject/types';
import { FieldGlobalDependentManager } from 'src/components/popupFormExtended/fieldGlobalDependent/FieldGlobalDependentManager';
import { FieldExtender } from 'src/components/popupFormExtended/fieldGlobalDependent/fieldExtender/FieldExtender';
import {
    DataFunctionDynamic,
    ValueForSuperiorFromApi,
    ValuesForSuperiorFromApi
} from 'src/components/popupFormExtended/fieldGlobalDependent/fieldsDependentManager/types';
import { TempFieldsState } from 'src/components/popupFormExtended/fieldGlobalDependent/types';
import { DynamicFieldT, PopupFormExtendedData } from 'src/data/popupFormTypes';
import { NumberManager } from 'src/utils/src/shared/NumberManager';

export type FieldDependentDefaultManagerProps = {
    formState: FormState;
    popupFormExtendedData: PopupFormExtendedData;
    tempFieldsState: TempFieldsState;
    fieldWasTriggered: FieldsPure;
    dataFunctionDynamic: DataFunctionDynamic;
    prefix?: string;
    relationId?: string;
};
export class FieldDependentDefaultManager extends FieldExtender {
    formState: FormState;
    popupFormExtendedData: PopupFormExtendedData;
    tempFieldsState: TempFieldsState;
    fieldWasTriggered: FieldsPure;
    fieldWasTriggeredDynamicFieldT: DynamicFieldT | null;
    dataFunctionDynamic: DataFunctionDynamic;
    fieldSuperiorOne: Fields | null;
    fieldSuperiorTwo: Fields | null;
    fieldSuperiorOneDynamicFieldT: DynamicFieldT | null;
    fieldSuperiorTwoDynamicFieldT: DynamicFieldT | null;
    valueFromSuperior: ValueForSuperiorFromApi;
    isRunNested: boolean;
    valuesFromSuperior: ValuesForSuperiorFromApi;
    isCaptureValuesFromSuperior: boolean;
    constructor({
        formState,
        popupFormExtendedData,
        tempFieldsState,
        fieldWasTriggered,
        dataFunctionDynamic,
        relationId,
        prefix
    }: FieldDependentDefaultManagerProps) {
        super({
            formState: formState,
            popupFormExtendedData: popupFormExtendedData,
            tempFieldsState: tempFieldsState,
            relationId,
            prefix
        });
        this.formState = formState;
        this.popupFormExtendedData = popupFormExtendedData;
        this.tempFieldsState = tempFieldsState;

        // Data For me
        this.fieldWasTriggered = fieldWasTriggered;
        this.dataFunctionDynamic = dataFunctionDynamic;
        this.fieldWasTriggeredDynamicFieldT = this.findDynamicFieldTById(fieldWasTriggered.id);
        this.fieldSuperiorOne = null;
        this.fieldSuperiorTwo = null;
        this.fieldSuperiorOneDynamicFieldT = null;
        this.fieldSuperiorTwoDynamicFieldT = null;
        this.valueFromSuperior = {
            value: 'EMPTY',
            text: ''
        };
        // Data For me

        this.isRunNested = false;
        // Data For child Start
        this.valuesFromSuperior = {};
        this.isCaptureValuesFromSuperior = false;
        // Data For child End
        this.prepareStateFieldsSuperior();
    }

    // GET STATE OF SUPERIOR FIELD
    // funkcja prepareStateFieldsSuperior() głównie przygotowywuje/przypisuje pola nadrzedne do wyliczeń
    private async prepareStateFieldsSuperior() {
        switch (this.dataFunctionDynamic.typeOfFunction) {
            case 'przeliczWartosc':
                {
                    const fieldSuperiorO = this.findStateOfFieldsByCode(
                        this.fieldWasTriggeredDynamicFieldT?.pole_do_wyliczen_1_kod
                    );
                    const fieldSuperiorT = this.findStateOfFieldsByCode(
                        this.fieldWasTriggeredDynamicFieldT?.pole_do_wyliczen_2_kod
                    );

                    this.fieldSuperiorOne = fieldSuperiorO ?? null;
                    this.fieldSuperiorTwo = fieldSuperiorT ?? null;
                    this.fieldSuperiorOneDynamicFieldT = this.findDynamicFieldTById(
                        String(this.fieldWasTriggeredDynamicFieldT?.pole_do_wyliczen_1)
                    );
                    this.fieldSuperiorTwoDynamicFieldT = this.findDynamicFieldTById(
                        String(this.fieldWasTriggeredDynamicFieldT?.pole_do_wyliczen_2)
                    );
                }
                break;
            case 'zmienPole':
                {
                    const fieldSuperiorO = this.findStateOfFieldsByCode(
                        this.fieldWasTriggeredDynamicFieldT?.pole_nadrzedne_kod
                    );
                    this.fieldSuperiorOne = fieldSuperiorO ?? null;

                    this.fieldSuperiorOneDynamicFieldT = this.findDynamicFieldTById(
                        String(this.fieldWasTriggeredDynamicFieldT?.pole_nadrzedne)
                    );
                }
                break;
            case 'pokazPole':
                {
                    const fieldSuperiorO = this.findStateOfFieldsByCode(
                        this.fieldWasTriggeredDynamicFieldT?.pole_widocznosc_kod
                    );
                    this.fieldSuperiorOne = fieldSuperiorO ?? null;
                    this.fieldSuperiorOneDynamicFieldT = this.findDynamicFieldTById(
                        String(this.fieldWasTriggeredDynamicFieldT?.pole_widocznosc)
                    );
                }
                break;
            case 'zmienWartosciPolZNadrzednego':
                this.valueFromSuperior = this.dataFunctionDynamic.valueToSet;
                break;
            default:
                break;
        }
    }

    // START  - COMMON CODE FOR zmienPole()

    async zmienPole() {}

    protected async getValueToSetForChangeField() {
        const data: {
            opcje: { [key: string]: string };
            optionAutoComplete: { id: string; text: string }[];
        } = {
            opcje: {},
            optionAutoComplete: []
        };
        if (this.fieldSuperiorOne?.value) {
            if (!this.fieldWasTriggeredDynamicFieldT) {
                console.error(
                    'FieldDependentDefaultManager: fieldWasTriggeredDynamicFieldT is null'
                );
                return { opcje: {}, optionAutoComplete: [] };
            }
            switch (this.fieldSuperiorOne?.typ) {
                case 'lista':
                case 'lista_cena':
                case 'lista_zalezna':
                    if (this.fieldWasTriggeredDynamicFieldT?.opcje_nadrzedne) {
                        const optToSet = this.fieldWasTriggeredDynamicFieldT.opcje_nadrzedne[
                            this.fieldSuperiorOne.value.value ?? ''
                        ] as { [key: string]: string } | undefined;
                        const options = safeGetKeyValueOptionsWithAddNewObjectOption(
                            optToSet ? optToSet : {},
                            this.fieldWasTriggeredDynamicFieldT
                        );
                        data.opcje = options;
                        data.optionAutoComplete =
                            DynamicFormObjectDataConverter.getArrayIdAndText(options);
                    }
                    break;
                case 'powiazanie_typ':
                    {
                        const params = {
                            q: this.fieldSuperiorOne?.value.value,
                            typ_nadrzedne: this.fieldSuperiorOne?.value.select,
                            typ_podrzedne: this.fieldWasTriggeredDynamicFieldT?.tabela_opcji,
                            id_pola: this.fieldWasTriggered?.id
                        };
                        const res = await getFieldAdditionalData({
                            url: 'json_ogolnie_podrzedne.php',
                            params
                        });
                        if (res.success) {
                            const options = safeGetArrayOptionsWithAddNewObjectOption(
                                res.data,
                                this.fieldWasTriggeredDynamicFieldT
                            );
                            data.optionAutoComplete = options;
                            data.opcje = DynamicFormObjectDataConverter.setObjectKeyValue(options);
                        }
                    }
                    break;
                case 'powiazanie_typ_wielokrotne':
                    {
                        const q = this.fieldSuperiorOne.value.objects.reduce((acc, item) => {
                            if (acc) {
                                return acc + ';' + item.typ + '_' + item.id;
                            }
                            return acc + item.typ + '_' + item.id;
                        }, '');

                        const params2 = {
                            q,
                            typ_podrzedne: this.fieldSuperiorOne?.value.select,
                            id_pola: this.fieldWasTriggered?.id
                        };
                        const res = await getFieldAdditionalData({
                            url: 'json_ogolnie_podrzedne.php',
                            params: params2
                        });
                        if (res.success) {
                            const options = safeGetArrayOptionsWithAddNewObjectOption(
                                res.data,
                                this.fieldWasTriggeredDynamicFieldT
                            );
                            data.optionAutoComplete = options;
                            data.opcje = DynamicFormObjectDataConverter.setObjectKeyValue(options);
                        }
                    }
                    break;
                case 'lista_obca':
                    {
                        const params = {
                            q: this.fieldSuperiorOne?.value.value,
                            typ_nadrzedne:
                                this.fieldWasTriggeredDynamicFieldT?.pole_nadrzedne_tabela_opcji,
                            typ_podrzedne: this.fieldWasTriggeredDynamicFieldT?.tabela_opcji,
                            id_pola: this.fieldWasTriggered?.id
                        };
                        const res = await getFieldAdditionalData({
                            url: 'json_ogolnie_podrzedne.php',
                            params
                        });
                        if (res.success) {
                            const options = safeGetArrayOptionsWithAddNewObjectOption(
                                res.data,
                                this.fieldWasTriggeredDynamicFieldT
                            );
                            data.optionAutoComplete = options;
                            data.opcje = DynamicFormObjectDataConverter.setObjectKeyValue(options);
                        }
                    }
                    break;
                default:
                    break;
            }
        }
        return data;
    }

    // END  - COMMON CODE FOR zmienPole()

    // START  - COMMON CODE FOR zmienWartoscPola()

    async zmienWartoscPola() {}

    protected async getValueToSetForChangeValueField() {
        let valueToSet: string | null = null;
        let isTheSameValueFound = false;
        this.fieldWasTriggeredDynamicFieldT?.opcje_opcja_domyslna?.forEach((item) => {
            if (!isTheSameValueFound) {
                const data = this.findStateOfFieldsByCode(item.kod_pola_nadrzednego);
                if (data?.value) {
                    switch (data.typ) {
                        case 'lista_zalezna':
                            if (
                                data.value.value === item.wartosc_pola_nadrzednego &&
                                item.id_pola_nadrzednego === data.id
                            ) {
                                valueToSet = item.opcja_domyslna;
                                isTheSameValueFound = true;
                            }
                            break;
                        case 'lista':
                        case 'lista_cena':
                            if (
                                data.value.value === item.wartosc_pola_nadrzednego &&
                                item.id_pola_nadrzednego === data.id
                            ) {
                                valueToSet = item.opcja_domyslna;
                                isTheSameValueFound = true;
                            }
                            break;
                        case 'checkbox':
                            {
                                const value = data.value ? '1' : '0';
                                if (
                                    value === item.wartosc_pola_nadrzednego &&
                                    item.id_pola_nadrzednego === data.id
                                ) {
                                    valueToSet = item.opcja_domyslna;
                                    isTheSameValueFound = true;
                                }
                            }
                            break;
                        default:
                            break;
                    }
                }
            }
        });
        return valueToSet;
    }

    // END  - COMMON CODE FOR zmienWartoscPola()

    // START  - COMMON CODE FOR pokazPole()

    async pokazPole() {
        if (!this.fieldWasTriggered.isUpdatedVisibility) {
            await this.checkAndChangeVisibilityStateOfField(this.fieldWasTriggered);
            await this.runNested(this.fieldWasTriggeredDynamicFieldT);
        }
    }

    private async checkAndChangeVisibilityStateOfField(fieldState: FieldsPure) {
        if (fieldState.initialHidden) return;
        let valueToCheck = 'BLOCK';
        const oldHidden = fieldState.hidden ? '1' : '-1';
        let hidden = fieldState.hidden ? '1' : '-1';

        switch (this.fieldSuperiorOne?.typ) {
            case 'lista':
                valueToCheck = this.fieldSuperiorOne.value.value ?? 'BLOCK';
                break;
            case 'checkbox':
                valueToCheck = this.fieldSuperiorOne.value ? '1' : '-1';
                break;
            default:
                break;
        }

        if (valueToCheck === 'BLOCK') return;

        hidden = this.fieldWasTriggeredDynamicFieldT?.opcje_widocznosc?.includes(valueToCheck)
            ? '-1'
            : '1';
        const newValue = {
            ...fieldState,
            hidden: hidden === '1'
        };

        if (hidden !== oldHidden) {
            this.setTempFieldsState(newValue, 'isUpdatedVisibility');
            this.isRunNested = true;
        }
    }

    // END  - COMMON CODE FOR pokazPole()

    // START  - COMMON CODE FOR zmienWartosciPolZNadrzednego()

    async zmienWartosciPolZNadrzednego() {
        await this.checkIfSetValueFromSuperior();
        this.checkLockAfterSetValueFromSuperior();
        await this.runNested(this.fieldWasTriggeredDynamicFieldT);
    }

    private async checkIfSetValueFromSuperior() {
        let newValue = {
            ...this.fieldWasTriggered
        };
        if (this.valueFromSuperior.value !== 'EMPTY') {
            switch (this.fieldWasTriggered?.typ) {
                case 'checkbox':
                    {
                        const bool = String(this.valueFromSuperior.value) === '1';

                        if (this.fieldWasTriggered.value !== bool) {
                            newValue = {
                                ...this.fieldWasTriggered,
                                value: bool
                            };
                        }
                    }
                    break;
                case 'tekst_z_przedrostkiem':
                    if (this.fieldWasTriggered.value.prefix !== this.valueFromSuperior.value) {
                        newValue = {
                            ...this.fieldWasTriggered,
                            value: {
                                ...this.fieldWasTriggered.value,
                                prefix: this.valueFromSuperior.value.toString()
                            }
                        };
                    }
                    break;
                case 'liczba':
                    if (this.fieldWasTriggered.value !== this.valueFromSuperior.value) {
                        newValue = {
                            ...this.fieldWasTriggered,
                            value: NumberManager.getSafeNumberAsStringFromString(
                                this.valueFromSuperior.value
                            )
                        };
                    }
                    break;
                case 'tekst_bez_ograniczen':
                case 'tekst':
                case 'pesel':
                case 'nip':
                    if (this.fieldWasTriggered.value !== this.valueFromSuperior.value) {
                        newValue = {
                            ...this.fieldWasTriggered,
                            value: this.valueFromSuperior.value.toString()
                        };
                    }
                    break;
                case 'lista':
                case 'lista_cena':
                    if (this.fieldWasTriggered.value.value !== this.valueFromSuperior.value) {
                        newValue = {
                            ...this.fieldWasTriggered,
                            value: {
                                ...this.fieldWasTriggered.value,
                                value: this.valueFromSuperior.value.toString()
                            }
                        };
                    }
                    break;
                case 'lista_obca':
                    if (this.fieldWasTriggered.value.value !== this.valueFromSuperior.value) {
                        newValue = {
                            ...this.fieldWasTriggered,
                            value: {
                                ...this.fieldWasTriggered.value,
                                value: this.valueFromSuperior.value.toString(),
                                text: this.valueFromSuperior.text
                            }
                        };
                    }
                    break;
                case 'lista_zalezna':
                    if (this.fieldWasTriggered.value.value !== this.valueFromSuperior.value) {
                        newValue = {
                            ...this.fieldWasTriggered,
                            value: {
                                ...this.fieldWasTriggered.value,
                                value: this.valueFromSuperior.value.toString()
                            }
                        };
                    }
                    break;
                case 'koszty':
                    if (this.fieldWasTriggered.value.value !== this.valueFromSuperior.value) {
                        newValue = {
                            ...this.fieldWasTriggered,
                            value: {
                                ...this.fieldWasTriggered.value,
                                value: this.valueFromSuperior.value.toString()
                            }
                        };
                    }
                    break;
                case 'lista_wielokrotna':
                case 'lista_wielokrotna_cena':
                case 'lista_obca_wielokrotna':
                    {
                        const ids = this.valueFromSuperior.value.toString().split('_');
                        const opcje = this.fieldWasTriggered.value.opcje;
                        newValue = {
                            ...this.fieldWasTriggered,
                            value: {
                                ...this.fieldWasTriggered.value,
                                values: ids.map((id) => {
                                    return {
                                        id: id,
                                        text: opcje.find((item) => item.id === id)?.text ?? ''
                                    };
                                })
                            }
                        };
                    }
                    break;
                case 'powiazanie_typ':
                    if (this.fieldWasTriggered.value.value !== this.valueFromSuperior.value) {
                        const val = this.valueFromSuperior.value.toString().split('_');
                        if (Array.isArray(val) && val[1]) {
                            newValue = {
                                ...this.fieldWasTriggered,
                                value: {
                                    ...this.fieldWasTriggered.value,
                                    select: val[0] ?? this.fieldWasTriggered.value.select,
                                    value: val[1],
                                    text: this.valueFromSuperior.text,
                                    type: val[0] ?? this.fieldWasTriggered.value.select
                                }
                            };
                        } else {
                            newValue = {
                                ...this.fieldWasTriggered,
                                value: {
                                    ...this.fieldWasTriggered.value,
                                    value: this.valueFromSuperior.value.toString(),
                                    text: this.valueFromSuperior.text,
                                    type: this.fieldWasTriggered.value.select
                                }
                            };
                        }
                    }
                    break;
                case 'data':
                    {
                        const date = DateManager.setDateFromStrDDMMYYYY(
                            this.valueFromSuperior.value.toString()
                        );
                        newValue = {
                            ...this.fieldWasTriggered,
                            value: date
                        };
                    }
                    break;
                case 'data_godzina':
                    {
                        const date = DateManager.setDateFromStrDDMMYYYY(
                            this.valueFromSuperior.value.toString()
                        );
                        newValue = {
                            ...this.fieldWasTriggered,
                            value: {
                                ...this.fieldWasTriggered.value,
                                date: date
                            }
                        };
                    }
                    break;
                case 'cena':
                    {
                        const data = this.valueFromSuperior.value.toString().split('_');
                        if (Array.isArray(data) && data[1] && data[0] && data[2]) {
                            newValue = {
                                ...this.fieldWasTriggered,
                                value: {
                                    netto: data[0],
                                    brutto: String(Number(data[0]) * getVatStake(data[2])),
                                    vat: data[2],
                                    waluta: data[1]
                                }
                            };
                        }
                    }
                    break;
                case 'numer':
                    {
                        const data = this.valueFromSuperior.value.toString().split('_');
                        if (Array.isArray(data)) {
                            newValue = {
                                ...this.fieldWasTriggered
                            };
                            if (data[0]) newValue.value.prefix = data[0];
                            if (data[1]) newValue.value.interfix = data[1];
                            if (data[2]) newValue.value.suffix = data[2];
                        }
                    }
                    break;
                case 'adres':
                    try {
                        const data = JSON.parse(this.valueFromSuperior.value.toString());
                        newValue = {
                            ...this.fieldWasTriggered,
                            value: {
                                dzielnica: data.dzielnica ? data.dzielnica : '',
                                gmina: data.gmina ? data.gmina : '',
                                id: data.id ? data.id : '',
                                kod: data.kod ? data.kod : '',
                                kraj: data.kraj ? data.kraj : 'POL',
                                miasto: data.miasto ? data.miasto : '',
                                nrdomu: data.nrdomu ? data.nrdomu : '',
                                nrmieszkania: data.nrmieszkania ? data.nrmieszkania : '',
                                numer_dzialki: data.numer_dzialki ? data.numer_dzialki : '',
                                numer_obrebu: data.numer_obrebu ? data.numer_obrebu : '',
                                poczta: data.poczta ? data.poczta : '',
                                powiat: data.powiat ? data.powiat : '',
                                typulicy: data.typulicy ? data.typulicy : '',
                                ulica: data.ulica ? data.ulica : '',
                                wojewodztwo: data.wojewodztwo ? data.wojewodztwo : '',
                                addressId: ''
                            }
                        };
                    } catch (error) {
                        //
                    }

                    break;
            }
        }
        this.setTempFieldsState(newValue, 'isUpdatedValueFromSuperior');
        this.isRunNested = true;
    }

    protected checkLockAfterSetValueFromSuperior() {
        if (this.fieldWasTriggeredDynamicFieldT?.zablokuj_po_ustawieniu_z_nadrzednego !== 't')
            return;
        this.setTempFieldsState(
            { ...this.fieldWasTriggered, isModify: false },
            'isUpdatedValueFromSuperior'
        );
    }

    // END  - COMMON CODE FOR zmienWartosciPolZNadrzednego()

    // START  - COMMON CODE FOR zmienWartosciPolZNadrzednegoModTextForm()

    async zmienWartosciPolZNadrzednegoModTextForm() {}

    protected async checkIfIsReturnValuesForChild() {
        if (this.isCaptureValuesFromSuperior && this.fieldWasTriggeredDynamicFieldT) {
            await new FieldGlobalDependentManager({
                formState: this.formState,
                tempFieldsState: this.tempFieldsState,
                popupFormExtendedData: this.popupFormExtendedData,
                relationId: this.relationId,
                prefix: this.prefix
            }).zmienWartosciPolZNadrzednego(
                this.fieldWasTriggeredDynamicFieldT,
                this.valuesFromSuperior
            );
        }
    }

    // END  - COMMON CODE FOR zmienWartosciPolZNadrzednegoModTextForm()

    // START  - COMMON CODE FOR przeliczWartosc()

    async przeliczWartosc() {}

    private getRound() {
        let round = 1000;
        if (
            this.fieldWasTriggeredDynamicFieldT?.liczba_po_przecinku &&
            Number(this.fieldWasTriggeredDynamicFieldT?.liczba_po_przecinku) > 0
        ) {
            round = Math.pow(10, Number(this.fieldWasTriggeredDynamicFieldT.liczba_po_przecinku));
        }
        return round;
    }

    protected getValuesToSet() {
        const values = {
            one: getValuesFromFields(this.fieldSuperiorOne),
            two: getValuesFromFields(this.fieldSuperiorTwo),
            round: this.getRound()
        };
        return values;
    }

    // END - COMMON CODE FOR przeliczWartosc()

    async runNested(dynamicFieldT: DynamicFieldT | null) {
        if (this.isRunNested && dynamicFieldT) {
            await new FieldGlobalDependentManager({
                formState: this.formState,
                tempFieldsState: this.tempFieldsState,
                popupFormExtendedData: this.popupFormExtendedData,
                relationId: this.relationId,
                prefix: this.prefix
            }).executeNested(dynamicFieldT);
        }
    }
}
