import store from '../../index';

export default store;

export type { AppDispatch, AppThunk, RootState } from '../../index';

export { systemSlice, userSlice } from '../../rootReducer';

export {
    closePopup,
    callPopup,
    closePopupAll,
    setPopupWithoutData,
    setPopupListScreenBasic,
    setPopup,
    setPopupInfoTwo,
    setPopupWarning,
    setPopupPrompt,
    updatePopup
} from './popup/popupSlice';
