import React, { FC, useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import PopupTemplateThird from 'src/components/shared/organisms/popupTemplateThird/PopupTemplateThird';
import { useAppDispatch } from 'src/store';
import { closePopup } from 'src/store/src/popup';
import { globalPopup } from 'src/constants/subtitles';
import { Button } from '@mui/material';
import ContentScroll from 'src/components/shared/molecules/contentScroll/ContentScroll';
import styles from './PopupContentPrompt.module.css';
import { EventBus } from 'src/utils/src/shared/EventBus';
import { UiInputNormal } from '../../atoms/uiInputs/uiInputNormal/UiInputNormal';
import { PopupStatePrompt } from 'src/store/src/popup/popup/types';
import { PopupContentProps } from '../../organisms/globalPopup/types';

export const PopupContentPrompt: FC<PopupContentProps<PopupStatePrompt>> = ({ popup }) => {
    const theme = useTheme();
    const dispatch = useAppDispatch();

    const inputRef = useRef<HTMLInputElement | null>(null);

    const [text, setText] = useState(popup.placeholder ?? '');

    const submit = (e: any) => {
        e.stopPropagation();
        if (popup.submitCallbackEventId) EventBus.$emit(popup.submitCallbackEventId, text);

        dispatch(closePopup(popup.key));
    };

    const cancel = () => {
        dispatch(closePopup(popup.key));
    };

    useEffect(() => {
        if (popup.focusText) inputRef.current?.select();
    });

    return (
        <PopupTemplateThird title={popup.title}>
            <div className={styles.wrapper}>
                <ContentScroll maxWidth={800} maxHeight={300}>
                    <p
                        style={{
                            color: theme.palette.primary.main,
                            fontSize: '16px',
                            padding: '5px 0'
                        }}>
                        {popup.message}
                    </p>
                    <UiInputNormal
                        ref={inputRef}
                        value={text}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setText(e.target.value)
                        }
                        onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
                            if (e.key === 'Enter' || e.keyCode === 13) submit(e);
                        }}
                    />
                </ContentScroll>
                <div className={styles.box}>
                    <Button
                        variant="contained"
                        sx={{
                            color: 'background.default',
                            padding: '6px 40px',
                            cursor: 'pointer'
                        }}
                        onClick={submit}>
                        <p>{globalPopup.contentPrompt.button.ok}</p>
                    </Button>
                    <Button
                        variant="contained"
                        sx={{
                            color: 'background.default',
                            padding: '6px 40px',
                            cursor: 'pointer'
                        }}
                        onClick={cancel}>
                        <p>{globalPopup.contentPrompt.button.cancel}</p>
                    </Button>
                </div>
            </div>
        </PopupTemplateThird>
    );
};
