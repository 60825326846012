import React, { FC } from 'react';
import ButtonCustom from 'src/components/shared/atoms/button/Button';
import { ApiActionSaveMassEditObjectsProps } from './types';
import { EventBus } from 'src/utils/src/shared/EventBus';

const ApiActionSaveMassEditObjects: FC<ApiActionSaveMassEditObjectsProps> = ({ children }) => {
    const handleApiAction = async () => {
        EventBus.$emit('massEditObjectsSave');
    };

    return (
        <ButtonCustom onClick={() => handleApiAction()} withoutStyles={true}>
            {children}
        </ButtonCustom>
    );
};

export default ApiActionSaveMassEditObjects;
