import React, { FC } from 'react';
import { TextareaAutosize } from '@mui/base';
import { useTheme } from '@mui/material/styles';
import { Editor } from '@tinymce/tinymce-react';

import { getColorForInputWithAllFlag } from 'src/styles/getColor';
import { tinymce_configArray } from 'src/constants/configTinymce';
import FieldValueToAcceptWrapper from '../shared/fieldValueToAcceptWrapper/FieldValueToAcceptWrapper';
import { UIFieldTextWithoutRestrictProps } from 'src/components/popupFormExtended/dynamicField/fieldTextWithoutRestrict/types';
import { UiFieldLabel } from 'src/components/popupFormExtended/dynamicField/shared/uiFieldLabel/UiFieldLabel';
import { UiWrapper } from 'src/components/popupFormExtended/dynamicField/shared/uiWrapper/UiWrapper';

export const UIFieldTextWithoutRestrict: FC<UIFieldTextWithoutRestrictProps> = (props) => {
    const theme = useTheme();
    const {
        value,
        uiFieldCommonProps,
        onChange,
        onBlur,
        onChangeW,
        uiFieldSharedProps,
        onAcceptValue
    } = props;
    const { archived, disabled } = uiFieldCommonProps;
    const height = uiFieldSharedProps.dynamicFieldT.rozmiar
        ? uiFieldSharedProps.dynamicFieldT.rozmiar
        : 2;

    if (uiFieldSharedProps.dynamicFieldT.wysiwyg === 't') {
        return (
            <UiWrapper>
                <UiFieldLabel {...uiFieldCommonProps} {...uiFieldSharedProps} />
                <FieldValueToAcceptWrapper
                    valueToAccept={uiFieldCommonProps.valueToAccept}
                    value={value}
                    onAcceptValue={onAcceptValue}>
                    <Editor
                        tinymceScriptSrc={'./tinymce/tinymce.min.js'}
                        initialValue={value}
                        init={{
                            ...tinymce_configArray[0]
                        }}
                        onBlur={(e) => onChangeW(e.target.getContent(), true)}
                    />
                </FieldValueToAcceptWrapper>
            </UiWrapper>
        );
    }

    return (
        <UiWrapper>
            <UiFieldLabel {...uiFieldCommonProps} {...uiFieldSharedProps} />
            <FieldValueToAcceptWrapper
                valueToAccept={uiFieldCommonProps.valueToAccept}
                value={value}
                onAcceptValue={onAcceptValue}>
                <TextareaAutosize
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    minRows={height}
                    disabled={disabled}
                    {...uiFieldSharedProps.inputProps}
                    style={{
                        width: '100%',
                        border: `solid 1px ${getColorForInputWithAllFlag({
                            theme,
                            flag: { ...uiFieldCommonProps }
                        })}`,
                        color: archived ? theme.palette.gray.second : theme.palette.primary.main
                    }}
                />
            </FieldValueToAcceptWrapper>
        </UiWrapper>
    );
};
