import React from 'react';
import fileDownload from 'js-file-download';
import { Dispatch, PayloadAction, ActionCreatorWithPayload } from '@reduxjs/toolkit';

import { downloadFileFromResponse } from 'src/utils';
import { combineFullUrl } from './combineFullUrl';
import { ProgressBarState } from 'src/store/src/general/progressBar/types';
import { setSpinner } from 'src/store/src/general/system/systemSlice';
import { BaseApiResponse } from 'src/api/types';
import { setPopupInfoTwo } from 'src/store/src/popup';
import { time } from 'console';

// export type UploadFileResponse = { [fileName: string]: string }; // filename to file id

export type UploadFileResponse = {
    [wewId: string]: {
        id: string;
        name: string;
        ext: string;
        size: number;
    };
}; // filename to file id
export class FileManager {
    static async loadPreviewFile({
        dispatch,
        data
    }: {
        dispatch: React.Dispatch<any>;
        data: Response;
    }) {
        const blob = await data.blob();
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
            const base64String = reader.result;
            dispatch(base64String);
        };
    }

    static async previewFile({ response }: { response: Response }) {
        const blob = await response.blob();
        // const url = URL.createObjectURL(blob);
        const pdfWindow = window.open('', '_black');
        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
            var base64data = reader.result;
            // console.log(base64data);
            // const iFrameUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${base64data}`;
            // const iFrameUrl2 = `https://docs.google.com/viewer?embedded=true&url=${base64data}`;
            pdfWindow?.document.write(
                "<iframe width='100%' height='100%' src='" + base64data + "'></iframe>"
            );
        };
    }

    static chooseExtension(key: string) {
        switch (key) {
            case 'downloadxls':
                return 'doc';
            case 'downloadpdf':
                return 'pdf';
            default:
                return '';
        }
    }

    static async downloadFile({ response, type }: { response: Response; type: string }) {
        const extension = FileManager.chooseExtension(type);
        const blob = await response.blob();
        await fileDownload(blob, `file.${extension}`);
    }

    static async downloadFiles({ responses, type }: { responses: Response[]; type: string }) {
        responses.forEach((response) => {
            (async () => {
                await FileManager.downloadFile({ response, type });
            })();
        });
    }

    static async handlePreviewFile({
        url,
        params,
        payload,
        body
    }: {
        url: string;
        params?: string;
        payload?: { key: string; value: string }[];
        body?: any;
    }) {
        const allUrl = combineFullUrl(url, new URLSearchParams(params));
        let allBody: string | FormData = '';
        if (body) {
            // stringlify only when it is needed, body could be already JSON string
            allBody = typeof body === 'string' ? body : JSON.stringify(body);
        }
        if (payload?.length) {
            const formData = new FormData();
            payload.forEach((item) => formData.append(item.key, String(item.value)));
            allBody = formData;
        }
        const response = await fetch(allUrl, {
            method: 'POST',
            body: allBody
        });
        if (response.status === 200) {
            await downloadFileFromResponse(response, 'default');
        }
    }

    static async handleDownloadFile({
        url,
        params,
        payload,
        body,
        dispatch,
        setProgress,
        method
    }: {
        url: string;
        params?: string;
        payload?: { key: string; value: string }[];
        body?: any;
        dispatch: Dispatch<PayloadAction<any>>;
        setProgress: ActionCreatorWithPayload<ProgressBarState, string>;
        setSpinner: ActionCreatorWithPayload<boolean, string>;
        method?: 'POST' | 'GET';
    }) {
        let isContentLength = true;
        dispatch(setSpinner(true));
        const allUrl = combineFullUrl(url, new URLSearchParams(params));
        let allBody: string | FormData | undefined = undefined;
        if (body) {
            // stringlify only when it is needed, body could be already JSON string
            allBody = typeof body === 'string' ? body : JSON.stringify(body);
        }
        if (payload?.length) {
            const formData = new FormData();
            payload.forEach((item) => formData.append(item.key, String(item.value)));
            allBody = formData;
        }

        const response = await fetch(allUrl, {
            method: method ?? 'POST',
            body: allBody
        });

        // check if api returned an error response
        const contentType = response.headers.get('Content-Type');
        const contentDisposition = response.headers.get('Content-Disposition');
        if (contentType?.includes('application/json') && contentDisposition == null) {
            const dataPrimary = await response.text();
            try {
                const dataParsed: BaseApiResponse<any> = JSON.parse(dataPrimary);
                if (!dataParsed.success) {
                    dispatch(
                        setPopupInfoTwo({
                            message: dataParsed.message,
                            title: 'Błąd',
                            messageInjectHtml: true
                        })
                    );
                }
            } catch (e) {
                // do not display error
            }
            dispatch(setSpinner(false));
            return;
        }

        const name =
            contentDisposition?.split('filename=')[1].split(';')[0].replaceAll('"', '') ??
            'dafaultName';
        const contentLength = response.headers.get('content-length')
            ? Number(response.headers.get('content-length'))
            : 0;
        if (contentLength) {
            dispatch(setSpinner(false));
            dispatch(
                setProgress({
                    progress: '0%',
                    isShowed: true
                })
            );
        } else {
            isContentLength = false;
        }
        let loaded = 0;
        try {
            const streamResponse = await new Response(
                new ReadableStream({
                    start(controller) {
                        const reader = response!.body!.getReader();
                        read();
                        function read() {
                            reader
                                .read()
                                .then((progressEvent) => {
                                    if (progressEvent.done) {
                                        controller.close();
                                        return;
                                    }
                                    if (contentLength) {
                                        loaded += progressEvent.value.byteLength;
                                        const progressActual = Math.min(
                                            Math.round((loaded / contentLength) * 100),
                                            100
                                        );
                                        const percentageComplete = progressActual + '%';
                                        if (percentageComplete === '100%') {
                                            dispatch(
                                                setProgress({
                                                    progress: '0%',
                                                    isShowed: false
                                                })
                                            );
                                        } else {
                                            dispatch(
                                                setProgress({
                                                    progress: percentageComplete,
                                                    isShowed: true
                                                })
                                            );
                                        }
                                    }
                                    controller.enqueue(progressEvent.value);
                                    read();
                                })
                                .catch((error) => {
                                    dispatch(
                                        setProgress({
                                            progress: '0%',
                                            isShowed: false
                                        })
                                    );
                                    dispatch(
                                        setPopupInfoTwo({
                                            title: 'Informacja',
                                            message: 'Problem z pobraniem pliku'
                                        })
                                    );
                                });
                        }
                    }
                })
            );
            const blob = await streamResponse.blob();

            if (isContentLength) {
                dispatch(
                    setProgress({
                        progress: '0%',
                        isShowed: false
                    })
                );
            } else {
                dispatch(setSpinner(false));
            }
            if (typeof window.escape === 'function') {
                fileDownload(blob, decodeURIComponent(escape(name)));
            } else {
                fileDownload(blob, name);
            }
        } catch (error: any) {
            if (isContentLength) {
                dispatch(
                    setProgress({
                        progress: '0%',
                        isShowed: false
                    })
                );
            } else {
                dispatch(setSpinner(false));
            }
        }
    }

    static uploadFiles(
        url: string,
        files: File[],
        onProgress: (progress: number) => void
    ): Promise<BaseApiResponse<UploadFileResponse>> {
        return new Promise((resolve) => {
            const xhr = new XMLHttpRequest();

            xhr.upload.addEventListener('progress', (e) => onProgress(e.loaded / e.total));

            xhr.addEventListener('load', () => {
                const dataPrimary = xhr.responseText;

                let dataParsed = null;
                try {
                    dataParsed = JSON.parse(dataPrimary);
                } catch (e) {
                    resolve({
                        success: false,
                        errorCode: 500,
                        data: null,
                        message: (e as Error).message
                    });
                }
                resolve(dataParsed);
            });

            xhr.addEventListener('error', () =>
                resolve({ success: false, errorCode: 7, data: null, message: 'File upload failed' })
            );

            xhr.addEventListener('abort', () =>
                resolve({
                    success: false,
                    errorCode: 7,
                    data: null,
                    message: 'File upload aborted'
                })
            );

            xhr.open('POST', url, true);

            const formData = new FormData();
            Array.from(files).forEach((file, i) =>
                formData.append(String(String(file.lastModified) + String(file.size) + i), file)
            );
            xhr.send(formData);
        });
    }
}

type Timer = ReturnType<typeof setTimeout>;
class ReadableStreamErrorHandler {
    timeout: Timer;
    newTimeout: any;
    timeActual: number;
    timeStart: number;
    timeToSet: number;
    progressActual: number;
    dispatch: Dispatch<PayloadAction<any>>;
    setProgress: ActionCreatorWithPayload<ProgressBarState, string>;
    constructor({
        timeout,
        timeStart,
        progressActual,
        dispatch,
        setProgress
    }: {
        timeout: Timer;
        timeStart: number;
        progressActual?: number;
        dispatch: Dispatch<PayloadAction<any>>;
        setProgress: ActionCreatorWithPayload<ProgressBarState, string>;
    }) {
        this.progressActual = progressActual ? progressActual : 1;
        this.timeout = timeout;
        this.timeStart = timeStart;
        this.dispatch = dispatch;
        this.setProgress = setProgress;
        this.timeActual = Date.now();
        this.timeToSet = 0;
    }

    run() {
        clearTimeout(this.timeout);
        this.calcTimeForSet();
        this.setTimeOut();
        return this.newTimeout;
    }

    private calcTimeForSet() {
        const timeRel = this.timeActual - this.timeStart;
        const timeRelAll = (timeRel * 100) / this.progressActual;
        this.timeToSet = (timeRelAll - timeRel) * 20 + 10;
    }

    private setTimeOut() {
        this.newTimeout = setTimeout(() => {
            this.dispatch(
                this.setProgress({
                    progress: '0%',
                    isShowed: false
                })
            );
            this.dispatch(
                setPopupInfoTwo({
                    title: 'Informacja',
                    message: 'Problem z pobraniem pliku'
                })
            );
            console.error('Problem z pobraniem pliku - prawdopodonie wynikajacy z wielkosci pliku');
        }, this.timeToSet);
    }
}
