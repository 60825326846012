import React, { FC } from 'react';
import { getNeededFieldParamsToUiFieldCommonPropsFull } from 'src/utils/src/popupFormExtended/getNeededFieldParamsToUiFieldCommonPropsFull';

import { FieldNumberWithCalcMinuteHourProps } from 'src/components/popupFormExtended/dynamicField/fieldNumber/types';
import { DynamicFormObjectStateHandler } from 'src/components/popupFormExtended/dynamicFormObject/DynamicFormObjectStateHandler';
import { dataSetInputKey } from 'src/constants/dataset';
import { doDependentCalculationsAfterFieldChanged } from '../../dynamicFormObject/doDependentCalculationsAfterFieldChanged';
import { prepareDefaultValueForFieldNumber } from 'src/utils/src/shared/prepareDefaultValueForFieldNumber';
import { UiFieldNumberWithCalcMinuteHour } from 'src/components/popupFormExtended/dynamicField/fieldNumber/UiFieldNumberWithCalcMinuteHour';
import { prepareValueToSetForFieldNumberWithCalcMinuteHour } from 'src/utils';

export const FieldNumberWithCalcMinuteHour: FC<FieldNumberWithCalcMinuteHourProps> = ({
    field,
    dynamicFieldT,
    dispatch,
    keyOfFieldInState,
    uiFieldCommonProps,
    popupFormExtendedData,
    prefix,
    relationId
}) => {
    const [state, setState] = React.useState(prepareDefaultValueForFieldNumber(field.value));
    const onChange = (
        e: React.ChangeEvent<HTMLInputElement>,
        onBlur: boolean,
        type: 'decimal' | 'normal'
    ) => {
        console.log(e.target.value, 'e.target.value');
        const { valid, valuePrepared } = prepareValueToSetForFieldNumberWithCalcMinuteHour({
            type,
            newValue: e.target.value,
            oldValue: state,
            dynamicFieldT
        });
        console.log(valuePrepared, 'valuePrepared', valid);
        if (!valid) return;
        setState(valuePrepared);

        if (onBlur) {
            const newValue = {
                ...field,
                ...DynamicFormObjectStateHandler.overrideFieldPropertiesOnChange(),
                value: valuePrepared
            };
            dispatch({
                type: 'liczba',
                payload: {
                    code: keyOfFieldInState,
                    value: newValue
                }
            });
            dispatch(
                doDependentCalculationsAfterFieldChanged({
                    fieldState: newValue,
                    dynamicFieldT: dynamicFieldT,
                    popupFormExtendedData,
                    prefix,
                    relationId,
                    keyOfFieldInState
                })
            );
        }
    };

    React.useEffect(() => {
        if (JSON.stringify(field.value) !== JSON.stringify(state)) {
            setState(field.value ? field.value : '0');
        }
    }, [field.value]);

    return (
        <UiFieldNumberWithCalcMinuteHour
            value={state}
            onChangeNormal={(e) => onChange(e, false, 'normal')}
            onChangeDecimal={(e) => onChange(e, false, 'decimal')}
            onBlurDecimal={(e) => onChange(e, true, 'decimal')}
            onBlurNormal={(e) => onChange(e, true, 'normal')}
            uiFieldCommonProps={{
                ...getNeededFieldParamsToUiFieldCommonPropsFull(field),
                ...uiFieldCommonProps
            }}
            uiFieldSharedProps={{
                dynamicFieldT,
                inputProps: {
                    [dataSetInputKey]: keyOfFieldInState
                }
            }}
        />
    );
};
