type CreateFormDataFunctionFromObject = {
    type: 'object';
    data: { [key: string]: string };
};

type CreateFormDataFunction = CreateFormDataFunctionFromObject;

export const createFormData = (props: CreateFormDataFunction) => {
    const formData = new FormData();
    switch (props.type) {
        case 'object':
            Object.entries(props.data).forEach((item) => {
                formData.append(item[0], item[1]);
            });
            break;
        default:
            break;
    }
    return formData;
};
