import { useCallback } from 'react';
import { getPopupFormExtendedData } from 'src/api/src/popupFormExtended/getPopupFormExtendedData';
import { PopupFormExtendedData } from 'src/data/popupFormTypes';
import { SystemAction } from 'src/data/types';
import { AppDispatch, useAppDispatch } from 'src/store';
import { setSpinner } from 'src/store/src/general/system/systemSlice';
import { setPopupInfoTwo } from 'src/store/src/popup';
import { PopupManager } from 'src/store/src/popup/popup/PopupManager';

export const useOpenPopupFormExtended = () => {
    const dispatch: AppDispatch = useAppDispatch();

    return useCallback(
        async (action: SystemAction) => {
            if (action.behaviour.type !== 'popupFormExtended') {
                console.error('PopupFormExtended: action type is not valid');
                return;
            }

            dispatch(setSpinner(true));
            const actionData = await getPopupFormExtendedData(
                action.behaviour.data.screen,
                action.behaviour.data.objectId,
                action.behaviour.data.additionalParameters
            );

            if (!actionData.success) {
                dispatch(
                    setPopupInfoTwo({
                        message: actionData.message,
                        title: 'Nie można otworzyć formularza'
                    })
                );
                dispatch(setSpinner(false));
                return;
            }
            new PopupManager({
                dispatch,
                action
            }).setPopupDynamicExtended({
                popupFormExtendedData: {
                    ...actionData.data,
                    objectId: action.behaviour.data.objectId,
                    screen: action.behaviour.data.screen
                }
            });
            dispatch(setSpinner(false));
        },
        [dispatch]
    );
};
