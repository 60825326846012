import React, { useEffect } from 'react';
import { Typography } from '@mui/material';
import { general } from 'src/constants/subtitles';
import { useSafeNavigate, useSearchParam } from 'src/hooks';
import { navigateByCode } from 'src/utils';

/**
 * Resolve and redirect to location coded in code param
 */
const LinkResolver = () => {
    // link code with hidden navigation data
    const code = useSearchParam('code');
    const navigate = useSafeNavigate();

    useEffect(() => {
        const handleError = (reason: string) => {
            console.error(reason);

            navigate(`/?php_url=index.php&translate=${code}`, { replace: true });
        };

        const do_navigation = async () => {
            if (!code) return handleError(general.missingCodeParameter);
            const nav_status = await navigateByCode(code, navigate, true);
            if (!nav_status.success) {
                handleError(nav_status.message);
            }
        };
        do_navigation();
    }, [code, navigate]);

    return <Typography>{general.redirecting}</Typography>;
};

export default LinkResolver;
