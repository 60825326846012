import * as React from 'react';

import { Fields } from 'src/components/popupFormExtended/dynamicField/types';
import { FormState, FormActions } from 'src/components/popupFormExtended/dynamicFormObject/types';
import { DynamicFormObjectRequirementValidatorProps, TypeValidateRequirement } from './types.d';
import { FieldsNotValid, PlacementRun } from '../types';
import { DynamicFormObjectHelperValidator } from 'src/components/popupFormExtended/dynamicFormObject/dynamicFormObjectGlobalValidator/dynamicFormObjectHelperValidator/DynamicFormObjectHelperValidator';
import { PopupFormExtendedData } from 'src/data/popupFormTypes';
import { validationData } from 'src/constants/popupContentDynamicExtended/validation';
import { normaliseStringNumber } from 'src/utils';

const typeFnValidate = 'validateRequirement';
export class DynamicFormObjectRequirementValidator extends DynamicFormObjectHelperValidator {
    formState: FormState;
    fieldsNotValid: FieldsNotValid;
    popupFormExtendedData: PopupFormExtendedData;
    dispatchFormState: React.Dispatch<FormActions>;
    typeFnValidate: TypeValidateRequirement;
    placementRun: PlacementRun;
    keyOfFieldInState?: string;
    constructor({
        formState,
        dispatchFormState,
        popupFormExtendedData,
        dataRef,
        placementRun,
        keyOfFieldInState
    }: DynamicFormObjectRequirementValidatorProps) {
        super({
            dispatchFormState,
            typeFnValidate,
            dataRef
        });
        this.placementRun = placementRun;
        this.typeFnValidate = typeFnValidate;
        this.formState = formState;
        this.popupFormExtendedData = popupFormExtendedData;
        this.fieldsNotValid = {};
        this.dispatchFormState = dispatchFormState;
        this.keyOfFieldInState = keyOfFieldInState;
    }

    async validate() {
        for (const property in this.formState.fields) {
            if (this.placementRun === 'fieldAddExtend') {
                const prefixArr = property?.split('-');
                if (
                    this.formState.fields[property]?.isMandatory &&
                    this.formState.fields[property]?.isAddExtend &&
                    this.keyOfFieldInState &&
                    this.keyOfFieldInState === prefixArr[0]
                ) {
                    this.setIsValidFlag(property, this.formState.fields[property]);
                }
            } else {
                if (
                    this.formState.fields[property]?.isMandatory &&
                    !this.formState.fields[property]?.isAddExtend
                ) {
                    this.setIsValidFlag(property, this.formState.fields[property]);
                }
            }
        }
        for (const property in this.formState.fields) {
            const fieldNotValid = this.setIsErrorFlag(property, this.formState.fields[property]);
            if (fieldNotValid) {
                Object.assign(this.fieldsNotValid, { [property]: fieldNotValid });
            }
        }
        if (Object.keys(this.fieldsNotValid).length) {
            this.dispatchFormState({
                type: 'update_fields',
                payload: this.fieldsNotValid
            });
            const scrollKey = Object.keys(this.fieldsNotValid)[0];
            const fieldsArray = Object.values(this.fieldsNotValid);
            this.setValidationResult({
                typeOfInfo: 'Error',
                type: {
                    issue: this.typeFnValidate,
                    id_zakladki: fieldsArray[0]?.id_zakladki ?? null,
                    message: validationData[this.typeFnValidate].message,
                    title: validationData[this.typeFnValidate].title,
                    infoList: fieldsArray.map((item) => item?.name)
                },
                scrollKey
            });
        }
    }

    private setIsValidFlag(keyOfField: string, fieldState: Fields) {
        let isValid = true;

        const dynamicFieldT = this.popupFormExtendedData.fields.find(
            (item) => item.kod === keyOfField
        );
        if (!fieldState?.hidden) {
            switch (fieldState?.typ) {
                case 'login':
                case 'tekst':
                case 'tekst_bez_ograniczen':
                case 'numer':
                case 'numer_rachunku_bankowego':
                case 'haslo_otwarte':
                case 'haslo':
                case 'email':
                case 'data':
                    if (!fieldState.value) {
                        isValid = false;
                    }
                    break;
                case 'liczba':
                    if (!fieldState.value || !Number(fieldState.value)) {
                        isValid = false;
                    }
                    break;
                case 'lista':
                    if (fieldState.value.value === '0' || fieldState.value.value === '-1') {
                        isValid = false;
                    }
                    break;
                case 'powiazanie_typ':
                    if (
                        dynamicFieldT?.dozwolona_opcja_pusta !== 't' &&
                        (!fieldState.value.value || fieldState.value.value === '0')
                    ) {
                        isValid = false;
                    } else if (
                        dynamicFieldT?.dozwolona_opcja_pusta === 't' &&
                        (!fieldState.value.value || fieldState.value.value === '0') &&
                        !fieldState.value.isEmptyValue
                    ) {
                        isValid = false;
                    }
                    break;
                case 'lista_obca': {
                    if (!fieldState.value.value || fieldState.value.value === '0') {
                        isValid = false;
                    }
                    break;
                }
                case 'lista_wielokrotna':
                case 'lista_obca_wielokrotna':
                    if (!fieldState.value.values.length) {
                        isValid = false;
                    }
                    break;
                case 'powiazanie_typ_wielokrotne':
                    if (!fieldState.value.objects.length) {
                        isValid = false;
                    }
                    break;
                case 'koszty':
                    if (
                        !fieldState.value.value ||
                        isNaN(Number(normaliseStringNumber(fieldState.value.value)))
                    ) {
                        isValid = false;
                    }
                    break;
                case 'lista_zalezna':
                    if (!fieldState.value.value || fieldState.value.value === '0') {
                        isValid = false;
                    }
                    break;
                case 'checkbox':
                    if (!fieldState.value) {
                        isValid = false;
                    }
                    break;
                case 'plik':
                    if (fieldState.value.id === '' || fieldState.value.name === '') {
                        isValid = false;
                    }
                    break;
                case 'emaile_wielokrotne':
                    if (!fieldState.value.elements.length) {
                        isValid = false;
                    }
                    break;
                case 'pliki_wielokrotne':
                    if (!fieldState.value.length) {
                        isValid = false;
                    }
                    break;
                case 'cena_liczba':
                case 'cena':
                    if (
                        !fieldState.value.netto ||
                        Number(normaliseStringNumber(fieldState.value.netto)) === 0
                    ) {
                        isValid = false;
                    }
                    break;
                case 'data_wzgledna':
                case 'data_godzina':
                case 'data_godziny':
                    if (!fieldState.value.date) {
                        isValid = false;
                    }
                    break;
                default:
                    break;
            }
        }

        if (fieldState?.typ) {
            this.formState.fields[keyOfField] = {
                ...fieldState,
                isValid
            };
        }
    }

    private setIsErrorFlag(keyOfField: string, fieldState: Fields) {
        let isError = false;

        if (fieldState && !fieldState.isValid) isError = true;
        if (fieldState && fieldState.mandatoryAlternative.length) {
            fieldState.mandatoryAlternative.forEach((item) => {
                const found = this.formState.fields[item];
                if (found && found.isValid) isError = false;
            });
        }

        if (isError) {
            return {
                ...fieldState,
                isError
            };
        } else return null;
    }
}
