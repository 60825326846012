import React, { FC } from 'react';
import { Button } from '@mui/material';

import { DynamicFormButton } from 'src/components/shared/atoms/dynamicFormButton/DynamicFormButton';
import { UiInputButtonProps } from 'src/components/shared/atoms/uiInputs/types';

export const UiInputButtonNormal: FC<UiInputButtonProps> = (props) => {
    const { onClick, text, submit = false } = props;
    return (
        <DynamicFormButton
            submit={submit}
            styleWrapper={{ padding: '0' }}
            styleText={{ fontSize: '12px' }}
            onClick={onClick}
            styleButton={{ padding: '6px 10px', width: '100%' }}>
            {text}
        </DynamicFormButton>
    );
};
