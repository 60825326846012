import React, { FC } from 'react';
import { Routes as ReactRouterRoutes, Route } from 'react-router-dom';
import PHPMaper from 'src/components/shared/pages/phpMapper/PhpMapper';
import LinkResolver from '../linkResolver/LinkResolver';
import AutoHideableMainMenu from '../autoHideableMainMenu/AutoHideableMainMenu';
import MessageDiePage from 'src/components/shared/pages/messageDiePage/MessageDiePage';
import CalendarTop from 'src/components/calendar/pages/calendar/CalendarTop';
import ObjectList from 'src/components/listScreen/pages/objectList/ObjectList';
import ObjectItem from 'src/components/object/pages/object/Object';
import AddObjectPage from 'src/components/popupFormExtended/addObjectPage/AddObjectPage';
import { BookCorrespondenceMain } from 'src/components/bookCorrespondence/pages/bookCorrespondenceMain/BookCorrespondenceMain';
import { PatternGenerateMain } from 'src/components/patternGenerateScreen/pages/patternGenerateMain/PatternGenerateMain';
import { ConfigMain } from 'src/components/configScreen/pages/configMain/ConfigMain';
import { InstructionMain } from 'src/components/instructionScreen/pages/instructionMain/IstructionMain';
import { VideoHelpMain } from 'src/components/videoHelpScreen/pages/videoHelpMain/VideoHelpMain';
import { RaportMain } from 'src/components/raportScreen/pages/raportMain/RaportMain';
import { ObjectNotBillMain } from 'src/components/objectNotBillScreen/pages/objectNotBillMain/ObjectNotBillMain';
import { CreatingMain } from 'src/components/creatingScreen/pages/creatingMain/CreatingMain';
import { MessageMain } from 'src/components/messageScreen/pages/messageMain/MessageMain';
import { SendingDocMain } from 'src/components/sendingDocScreen/pages/sendingDocMain/SendingDocMain';
import { HelpMain } from 'src/components/helpScreen/pages/helpMain/HelpMain';
import { ImportPayoffMain } from 'src/components/importPayoff/pages/ImportPayoffMain/ImportPayoffMain';
import { MassEditObjectsMain } from 'src/components/massEditObjectsScreen/pages/MassEditObjectsMain/MassEditObjectsMain';
import { StartupMain } from 'src/components/startupscreen/pages/startupMain/StartupMain';

const Routes: FC<{ basename: string }> = ({ basename }) => {
    //!!! commented because it is not necessary now, but it can be usefull i narrow future
    // const location = useLocation();

    // set globally Location so non react components can affect browser history
    // useEffect(() => {
    //     window.reactLocation = location;
    //     return () => {
    //         window.reactLocation = undefined;
    //     };
    // }, [location]);

    return (
        <ReactRouterRoutes>
            <Route path="/link" element={<LinkResolver />} />
            <Route path="/" element={<AutoHideableMainMenu />}>
                <Route path="/message" element={<MessageDiePage />} />
                <Route path="/kalendarz" element={<CalendarTop />} />
                <Route path="/obiekt" element={<ObjectItem />} />
                <Route path="/dodaj-obiekt" element={<AddObjectPage />} />
                <Route path="/ksiegi-korespondencji" element={<BookCorrespondenceMain />} />
                <Route path="/szablony-generowane" element={<PatternGenerateMain />} />
                <Route path="/konfiguracja" element={<ConfigMain />} />
                <Route path="/instrukcje-pomoc" element={<InstructionMain />} />
                <Route path="/pomoc" element={<HelpMain />} />
                <Route path="/video-pomoc" element={<VideoHelpMain />} />
                <Route path="/wiadomosc" element={<MessageMain />} />
                <Route path="/import-wplat" element={<ImportPayoffMain />} />
                <Route path="/wysylka-dokumentow" element={<SendingDocMain />} />
                <Route path="/masowa-edycja-obiektow" element={<MassEditObjectsMain />} />
                <Route path="/obiekty-niezafakturowane" element={<ObjectNotBillMain />} />
                <Route
                    path="/tworzenie-przesylki-z-dokumentow"
                    element={<CreatingMain type="ekran_enadawca_tworzenie_przesylki" />}
                />
                <Route
                    path="/tworzenie-zbioru-przesylek"
                    element={<CreatingMain type="ekran_enadawca_tworzenie_zbioru" />}
                />
                <Route
                    path="/tworzenie-paczki-pozwow"
                    element={<CreatingMain type="ekran_esad_tworzenie_paczki" />}
                />
                <Route
                    path="/tworzenie-paczki-wnioskow"
                    element={<CreatingMain type="ekran_esad_tworzenie_paczki_wnioskow" />}
                />
                <Route path="/lista" element={<ObjectList view={'listy'} />} />
                <Route path="/lista-drzewo" element={<ObjectList view={'drzewo'} />} />
                <Route path="/lista-statusy" element={<ObjectList view={'statusy'} />} />
                <Route path="/raporty" element={<RaportMain />} />
                <Route path="/start" element={<StartupMain />} />
                <Route path="/" element={<PHPMaper basename={basename} />} />
                <Route path="*" element={<PHPMaper basename={basename} />} />
            </Route>
        </ReactRouterRoutes>
    );
};

export default Routes;
