import React, { FC } from 'react';
import { Autocomplete, AutocompleteRenderInputParams, InputBase } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import ButtonCross from 'src/components/shared/atoms/buttonCross/ButtonCross';
import style from './style.module.css';
import { useFieldClasses } from 'src/hooks';
import { createFormData, getTypeUrl } from 'src/utils';
import { getFieldAdditionalData } from 'src/api/src/shared/getFieldAdditionalData';
import { UiInputSelectTwo } from 'src/components/popupFormExtended/dynamicField/shared/uiInputSelectTwo/UiInputSelectTwo';
import LegacyCompatibleLink from 'src/components/shared/atoms/legacyCompatibleLink/LegacyCompatibleLink';
import { DynamicRelationshipTypeManyProps } from 'src/components/shared/atoms/dynamicRelationshipTypeMany/types';
import { DynamicLabel } from 'src/components/shared/atoms/dynamicLabel/DynamicLabel';

export const DynamicRelationshipTypeMany: FC<DynamicRelationshipTypeManyProps> = ({
    dynamicFieldT,
    field,
    fieldValue
}) => {
    const value = fieldValue.value;
    const theme = useTheme();
    const classes = useFieldClasses({ muiType: 'autocompleteOne' });

    const [inputValue, setInputValue] = React.useState('');
    const [optionToChoose, setOptionToChoose] = React.useState([]);

    const url = getTypeUrl({ field: dynamicFieldT });

    const getOptionsFromApi = (valueTyped: string) => {
        if (valueTyped != null && valueTyped.length > 0) {
            const params = {
                q: valueTyped,
                typ: value.select,
                id_pola_z_warunkiem: dynamicFieldT.id
            };
            const formData = createFormData({ type: 'object', data: params });
            getFieldAdditionalData({
                url,
                params: params,
                body: formData
            })
                .then((res) => {
                    if (res.data) {
                        setOptionToChoose(res.data);
                    } else {
                        setOptionToChoose([]);
                    }
                })
                .catch((err) => {
                    setOptionToChoose([]);
                });
        } else {
            setOptionToChoose([]);
        }
    };

    return (
        <div className={style.wrapper}>
            <DynamicLabel label={dynamicFieldT.nazwa} />
            <div className={style.box}>
                <UiInputSelectTwo
                    value={value.select}
                    opcje={dynamicFieldT.opcje ? dynamicFieldT.opcje : {}}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        field.onChange({
                            ...field.value,
                            value: {
                                ...value,
                                select: e.target.value
                            }
                        });
                    }}
                />
                <Autocomplete
                    sx={{
                        paddingLeft: '10px'
                    }}
                    freeSolo
                    onInputChange={(_, value: string, reason) => {
                        if (reason === 'input') {
                            setInputValue(value);
                            getOptionsFromApi(value);
                        }
                    }}
                    options={optionToChoose || null}
                    inputValue={inputValue}
                    getOptionLabel={(option: any) => {
                        return option?.text;
                    }}
                    renderOption={(props, option: any) => {
                        return (
                            <li {...props} key={option.id}>
                                {option.text}
                            </li>
                        );
                    }}
                    disablePortal={false}
                    onChange={(
                        _: React.SyntheticEvent<Element, Event>,
                        newValue: { id: string; text: string } | null | string
                    ) => {
                        if (newValue && typeof newValue !== 'string') {
                            const found = value.objects.find(
                                (item: any) => item.nazwa === newValue?.text
                            );
                            if (!found) {
                                setInputValue('');

                                const objects = [
                                    ...value.objects,
                                    {
                                        id: newValue.id,
                                        nazwa: newValue.text,
                                        typ: value.select,
                                        typ_nazwa: value.select
                                    }
                                ];
                                field.onChange({
                                    ...field.value,
                                    value: {
                                        ...value,
                                        objects
                                    }
                                });
                            }
                        }
                    }}
                    clearIcon={
                        inputValue ? (
                            <ButtonCross
                                onClick={() => {
                                    setInputValue('');
                                }}
                                style={{
                                    top: '2px'
                                }}
                            />
                        ) : (
                            <></>
                        )
                    }
                    classes={{
                        option: classes.option,
                        listbox: classes.listbox,
                        paper: classes.paper,
                        popper: classes.popper
                    }}
                    renderInput={(params: AutocompleteRenderInputParams) => {
                        return (
                            <InputBase
                                {...params.InputProps}
                                {...params}
                                sx={{
                                    border: `solid 1px ${theme.palette.primary.main}`,
                                    color: theme.palette.primary.main,
                                    borderRadius: '4px',
                                    paddingLeft: '10px'
                                }}
                            />
                        );
                    }}
                />
            </div>
            <div>
                {value.objects.length ? (
                    <div className={style.list}>
                        {value.objects.map((item: any) => {
                            return (
                                <div key={item.id} className={style.item}>
                                    <LegacyCompatibleLink
                                        url="ekran_obiektu.php"
                                        queryParams={
                                            new URLSearchParams([
                                                ['id', item.id ?? ''],
                                                ['obiekt', item.typ ?? '']
                                            ])
                                        }
                                        newWindow={true}>
                                        {item.typ_nazwa ? (
                                            <p>
                                                {item.typ_nazwa}:
                                                {item.nazwa!?.length > 40
                                                    ? item.nazwa?.substring(0, 40) + '...'
                                                    : item.nazwa}
                                            </p>
                                        ) : item.nazwa!?.length > 40 ? (
                                            item.nazwa?.substring(0, 40) + '...'
                                        ) : (
                                            item.nazwa
                                        )}
                                    </LegacyCompatibleLink>
                                    <div className={style.boxDelete}>
                                        <ButtonCross
                                            onClick={() => {
                                                const objects = value.objects.filter((object) => {
                                                    if (
                                                        item.id === object.id &&
                                                        item.typ === object.typ
                                                    ) {
                                                        return false;
                                                    }
                                                    return true;
                                                });
                                                const elements = value.elements.map((el) => {
                                                    if (item.id === el.id && item.typ === el.typ) {
                                                        return {
                                                            ...item,
                                                            isToDelete: true
                                                        };
                                                    }
                                                    return item;
                                                });
                                                field.onChange({
                                                    ...field.value,
                                                    value: {
                                                        ...value,
                                                        objects,
                                                        elements
                                                    }
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ) : null}
            </div>
        </div>
    );
};
