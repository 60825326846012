import { Box, Divider } from '@mui/material';
import React from 'react';
import styles from './TopMenu.module.css';
import WidgetsSection from 'src/components/menu/organisms/widgetsSection/WidgetsSection';
import MessagesSection from 'src/components/menu/organisms/messagesSection/MessagesSection';
import SearchBar from 'src/components/menu/organisms/searchBar/SearchBar';
import ShortcutsPinSection from 'src/components/menu/organisms/shortcutsPinSection/ShortcutsPinSection';
import UserSection from 'src/components/menu/organisms/userSection/UserSection';
import { LogoProduction } from 'src/components/menu/organisms/logo/LogoProduction';
import Logo from 'src/components/menu/organisms/logo/LogoDevelopment';
import { MENU_HEADER_HEIGHT } from 'src/constants';
import ShortcutsMenuWrapper from 'src/components/menu/organisms/shortcutsMenuWrapper/ShortcutsMenuWrapper';
import AddObjectShortcutsSection from '../addObjectShortcutsSection/AddObjectShortcutsSection';
import { useTypedSelector } from 'src/store';

const TopMenu = () => {
    const userType = useTypedSelector((state) => state.user.userType);
    const isWithoutGlobalSearch = useTypedSelector((state) => state.system.isWithoutGlobalSearch);

    return (
        <Box
            width={'100%'}
            height={MENU_HEADER_HEIGHT}
            display={'grid'}
            gridTemplateColumns={'auto 1fr auto'}
            alignItems={'center'}
            sx={{
                borderBottom: (theme) => `1px solid ${theme.palette.primary.main}`,
                background: (theme) => theme.palette.background.paper,
                padding: '0 18px'
            }}>
            <div className={styles.section}>
                {process.env.NODE_ENV === 'development' ? (
                    <Logo name={'logo.png'} />
                ) : (
                    <LogoProduction />
                )}
                <ShortcutsPinSection />
                <Divider orientation="vertical" sx={{ padding: 1 }} flexItem />
            </div>
            <div className={styles.sectionTwo}>
                <ShortcutsMenuWrapper />
                {isWithoutGlobalSearch ? null : (
                    <div className={styles.sectionTwoInner}>
                        <SearchBar />
                    </div>
                )}
            </div>
            <div className={`${styles.section} ${styles.sectionItemLast}`}>
                {userType === 'pracownicy' && (
                    <>
                        <AddObjectShortcutsSection />
                        <div className={styles.wrapperDivider}>
                            <Divider sx={{ margin: '0 5px' }} orientation="vertical" />
                        </div>
                    </>
                )}
                <WidgetsSection />
                <div className={styles.wrapperDivider}>
                    <Divider sx={{ margin: '0 5px' }} orientation="vertical" />
                </div>
                <MessagesSection />
                <UserSection />
            </div>
        </Box>
    );
};

export default React.memo(TopMenu);
