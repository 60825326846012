import React from 'react';
import { useNavigate } from 'react-router-dom';

import { saveMassEditObjects } from 'src/api/src/massEditObjectsScreen/saveMassEditObjects';
import { prepareFieldForApiRHF } from 'src/components/form/molecules/formObjectDataApiCreator/prepareFieldForApiRHF';
import { FormValidatorRHF } from 'src/components/form/molecules/formValidator/FormValidatorRHF';
import { MassEditObjectsFormStateSharedProps } from 'src/components/massEditObjectsScreen/organisms/massEditObjectsFormState/types';
import { FieldsStateRHF } from 'src/data/fieldsReactHookForm';
import { useAppDispatch, useTypedSelector } from 'src/store';
import { consoleConditionally } from 'src/utils';
import { HelperRHF } from 'src/utils/src/shared/HelperRHF';

type UseSubmitMassEditObjectProps = MassEditObjectsFormStateSharedProps & {};

export const useSubmitMassEditObjects = (props: UseSubmitMassEditObjectProps) => {
    const massEditObjects = useTypedSelector((state) => state.massEditObjects);
    const dispatchPopupStateRedux = useAppDispatch();
    const navigate = useNavigate();
    const editElements = React.useMemo(() => {
        return massEditObjects.row.map((row) => row.id);
    }, [massEditObjects.row]);

    const onSubmit = async (dataState: FieldsStateRHF) => {
        consoleConditionally(dataState, 'dataState');

        const result = await new FormValidatorRHF({
            dispatchPopupStateRedux,
            fieldsState: dataState,
            dynamicFieldT: massEditObjects.columnField,
            screen: 'massEditObjects',
            ...props
        }).validate();
        consoleConditionally(result, 'result');
        if (result.isNotValid) return;
        const dataPrepared = new DataApiCreatorMassEditObjects({
            editElements,
            obiekt: massEditObjects.params.obiekt_id,
            screen: massEditObjects.params.obiekt,
            dataState
        }).getData();
        const res = await saveMassEditObjects(dataPrepared);
        if (res.success) {
            navigate(-1);
        }
    };

    return onSubmit;
};

export type DataApiMassEditObjects = {
    params: URLSearchParams;
    body: {
        obiekt: string;
        screen: string;
        editElements: string[];
        objects: any;
    };
};

class DataApiCreatorMassEditObjects {
    obiekt: string;
    screen: string;
    data: DataApiMassEditObjects;
    dataState: FieldsStateRHF;
    constructor({
        editElements,
        obiekt,
        screen,
        dataState
    }: {
        editElements: string[];
        obiekt: string;
        screen: string;
        dataState: FieldsStateRHF;
    }) {
        this.obiekt = obiekt;
        this.screen = screen;
        this.dataState = dataState;
        this.data = {
            params: new URLSearchParams(),
            body: {
                obiekt: obiekt,
                screen: screen,
                editElements: editElements,
                objects: {}
            }
        };
    }

    getData(): DataApiMassEditObjects {
        this.setParams();
        this.setObjects();
        return this.data;
    }

    private setParams() {
        this.data.params = new URLSearchParams([['obiekt', this.obiekt]]);
    }

    private setObjects() {
        this.data.body.editElements.forEach((item) => {
            this.data.body.objects[item] = {};
            for (const key in this.dataState) {
                const [id, kod] = HelperRHF.getPropertiesKey({ key, screen: 'massEditObjects' });
                if (id === item) {
                    const dataField = prepareFieldForApiRHF(this.dataState[key], kod);
                    Object.assign(this.data.body.objects[id], dataField);
                }
            }
        });
    }
}
