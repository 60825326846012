import React, { FC } from 'react';
import { UtilityBarCrossIconProps } from './types';
import { UtilityBarOptionUi } from 'src/components/listScreen/molecules/utilityBarOptionUi/UtilityBarOptionUi';
import { CrossIcon } from 'src/components/shared/atoms/crossIcon/CrossIcon';

export const UtilityBarCrossIcon: FC<UtilityBarCrossIconProps> = ({ name }) => {
    return (
        <UtilityBarOptionUi name={name}>
            <CrossIcon />
        </UtilityBarOptionUi>
    );
};
