import * as React from 'react';
import { Dispatch, PayloadAction } from '@reduxjs/toolkit';

import { setPopupInfoTwo, setPopupWarning } from 'src/store/src/popup';
import { Actions, GlobalPopupState } from 'src/context/globalPopup/popupContext';
import { DynamicFormObjectRequirementValidator } from 'src/components/popupFormExtended/dynamicFormObject/dynamicFormObjectGlobalValidator/dynamicFormObjectRequirementValidator/DynamicFormObjectRequirementValidator';
import { DynamicFormObjectCollisionValidator } from 'src/components/popupFormExtended/dynamicFormObject/dynamicFormObjectGlobalValidator/dynamicFormObjectCollisionValidator/DynamicFormObjectCollisionValidator';
import { DynamicFormObjectLoginValidator } from 'src/components/popupFormExtended/dynamicFormObject/dynamicFormObjectGlobalValidator/dynamicFormObjectLoginValidator/DynamicFormObjectLoginValidator';
import { DynamicFormObjectDateValidator } from 'src/components/popupFormExtended/dynamicFormObject/dynamicFormObjectGlobalValidator/dynamicFormObjectDateValidator/DynamicFormObjectDateValidator';
import { FormState, FormActions } from 'src/components/popupFormExtended/dynamicFormObject/types';
import { PopupPayloadsAll } from 'src/store/src/popup/popup/types';
import {
    DynamicFormObjectGlobalValidatorProps,
    TypeFnValidate,
    DynamicFormObjectGlobalValidatorFnValidateProps,
    FieldRelatedObjectData,
    DataRef,
    PlacementRun
} from './types.d';
import { PopupFormExtendedData } from 'src/data/popupFormTypes';
import DynamicFormObjectFileUploadingValidator from './dynamicFormObjectFileUploadingValidator/DynamicFormObjectFileUploadingValidator';
import { PopupState } from 'src/store/src/popup/popup/types';
import { DynamicFormObjectNRBValidator } from 'src/components/popupFormExtended/dynamicFormObject/dynamicFormObjectGlobalValidator/dynamicFormObjectNRBValidator/DynamicFormObjectNRBValidator';
import { DynamicFormObjectNIPValidator } from 'src/components/popupFormExtended/dynamicFormObject/dynamicFormObjectGlobalValidator/dynamicFormObjectNIPValidator/DynamicFormObjectNIPValidator';
import { DynamicFormObjectPESELValidator } from 'src/components/popupFormExtended/dynamicFormObject/dynamicFormObjectGlobalValidator/dynamicFormObjectPESELValidator/DynamicFormObjectPESELValidator';
import { DynamicFormObjectCalendarCollisionValidator } from 'src/components/popupFormExtended/dynamicFormObject/dynamicFormObjectGlobalValidator/dynamicFormObjectCalendarCollisionValidator/DynamicFormObjectCalendarCollisionValidator';
import { DynamicFormObjectDateCorrectnessValidator } from 'src/components/popupFormExtended/dynamicFormObject/dynamicFormObjectGlobalValidator/dynamicFormObjectDateCorrectnessValidator/DynamicFormObjectDateCorrectnessValidator';
import { DynamicFormObjectInvoiceBillValidator } from 'src/components/popupFormExtended/dynamicFormObject/dynamicFormObjectGlobalValidator/dynamicFormObjectInvoiceBillValidator/DynamicFormObjectInvoiceBillValidator';
import { DynamicFormObjectMailValidator } from 'src/components/popupFormExtended/dynamicFormObject/dynamicFormObjectGlobalValidator/dynamicFormObjectMailValidator/DynamicFormObjectMailValidator';
import { SUBMIT_MODES } from '../DynamicFormObject';
import { DynamicFormObjectRelationExtandedForcedValidator } from 'src/components/popupFormExtended/dynamicFormObject/dynamicFormObjectGlobalValidator/dynamicFormObjectRelationExtandedForcedValidator/DynamicFormObjectRelationExtandedForcedValidator';
import { EventBus } from 'src/utils/src/shared/EventBus';
import { generateToken } from 'src/utils';
import { dataSetInputKey } from 'src/constants/dataset';
import { EventHandler } from 'src/utils/src/shared/EventHandler';

const funcionsAll: TypeFnValidate[] = [
    'validateFileLoading',
    'validateRequirement',
    'validateLogin',
    'validateCollision',
    'validateDate',
    'validateMail',
    'validateNRB',
    'validateNIP',
    'validateInvoiceBill',
    'validatePESEL',
    'validateDateCorrectness',
    'validateCalendarCollision',
    'validateRelationExtandedForced'
];

const funcionsFieldAddExtend: TypeFnValidate[] = ['validateRequirement'];
export class DynamicFormObjectGlobalValidator {
    formState: FormState;
    dispatchFormState: React.Dispatch<FormActions>;
    dataRef: DataRef;
    popupFormExtendedData: PopupFormExtendedData;
    dispatchPopupStateRedux: Dispatch<PayloadAction<PopupPayloadsAll>>;
    dispatchPopupStateContext: React.Dispatch<Actions>;
    popupStateContext: GlobalPopupState;
    popupStateRedux: PopupState;
    fieldRelatedObjectData: FieldRelatedObjectData;
    usedForAddingObject: boolean;
    submitMode: SUBMIT_MODES;
    placementRun: PlacementRun;
    keyOfFieldInState?: string;
    constructor({
        formState,
        dispatchFormState,
        dispatchPopupStateRedux,
        dispatchPopupStateContext,
        popupFormExtendedData,
        popupStateContext,
        popupStateRedux,
        submitMode,
        placementRun,
        keyOfFieldInState
    }: DynamicFormObjectGlobalValidatorProps) {
        this.formState = formState;
        this.dispatchFormState = dispatchFormState;
        this.dispatchPopupStateRedux = dispatchPopupStateRedux;
        this.popupStateContext = popupStateContext;
        this.popupStateRedux = popupStateRedux;
        this.dispatchPopupStateContext = dispatchPopupStateContext;
        this.popupFormExtendedData = popupFormExtendedData;
        this.usedForAddingObject = popupFormExtendedData.objectId === 'nowy';
        this.submitMode = submitMode;
        this.placementRun = placementRun;
        this.keyOfFieldInState = keyOfFieldInState;
        this.dataRef = {
            validationResult: {
                isFormValid: true,
                type: { issue: 'none', id_zakladki: popupStateContext.activeTab.id },
                typeOfInfo: 'none',
                breakValidationLoop: false,
                scrollKey: ''
            }
        };
        this.fieldRelatedObjectData = this.getFieldRelatedObjectData();
    }
    async execute() {
        let fnToRun: TypeFnValidate[] = [];
        switch (this.placementRun) {
            case 'fieldAddExtend':
                fnToRun = funcionsFieldAddExtend;
                break;
            default:
                fnToRun = funcionsAll;
                break;
        }
        for (const fn of fnToRun) {
            await this.validate({ fn });
            if (!this.dataRef.validationResult.isFormValid) {
                await this.setTabAndScrollKey();
                await this.handleResultValidation();
            }
            if (this.dataRef.validationResult.breakValidationLoop) break;
        }
        return this.dataRef.validationResult;
    }

    private getFieldRelatedObjectData() {
        return {
            id:
                this.popupFormExtendedData.objectId === 'nowy'
                    ? '0'
                    : this.popupFormExtendedData.objectId,
            screen: this.popupFormExtendedData.screen
        };
    }

    private async validate({ fn }: DynamicFormObjectGlobalValidatorFnValidateProps) {
        await this[fn]();
    }

    private async validateFileLoading() {
        new DynamicFormObjectFileUploadingValidator({
            fields: this.formState.fields,
            dispatchFormState: this.dispatchFormState,
            dataRef: this.dataRef
        }).validate();
    }

    private async validateRequirement() {
        await new DynamicFormObjectRequirementValidator({
            formState: this.formState,
            dispatchFormState: this.dispatchFormState,
            popupFormExtendedData: this.popupFormExtendedData,
            dataRef: this.dataRef,
            placementRun: this.placementRun,
            keyOfFieldInState: this.keyOfFieldInState
        }).validate();
    }

    private async validateCollision() {
        await new DynamicFormObjectCollisionValidator({
            formState: this.formState,
            dispatchFormState: this.dispatchFormState,
            popupFormExtendedData: this.popupFormExtendedData,
            fieldRelatedObjectData: this.fieldRelatedObjectData,
            dataRef: this.dataRef
        }).validate();
    }

    private async validateInvoiceBill() {
        await new DynamicFormObjectInvoiceBillValidator({
            formState: this.formState,
            dispatchFormState: this.dispatchFormState,
            popupFormExtendedData: this.popupFormExtendedData,
            fieldRelatedObjectData: this.fieldRelatedObjectData,
            dataRef: this.dataRef,
            usedForAddingObject: this.usedForAddingObject
        }).validate();
    }

    private async validateLogin() {
        await new DynamicFormObjectLoginValidator({
            formState: this.formState,
            dispatchFormState: this.dispatchFormState,
            popupFormExtendedData: this.popupFormExtendedData,
            fieldRelatedObjectData: this.fieldRelatedObjectData,
            dataRef: this.dataRef
        }).validate();
    }

    private async validateNRB() {
        await new DynamicFormObjectNRBValidator({
            formState: this.formState,
            dispatchFormState: this.dispatchFormState,
            popupFormExtendedData: this.popupFormExtendedData,
            fieldRelatedObjectData: this.fieldRelatedObjectData,
            dataRef: this.dataRef
        }).validate();
    }
    private async validateDate() {
        await new DynamicFormObjectDateValidator({
            formState: this.formState,
            dispatchFormState: this.dispatchFormState,
            popupFormExtendedData: this.popupFormExtendedData,
            fieldRelatedObjectData: this.fieldRelatedObjectData,
            dataRef: this.dataRef
        }).validate();
    }

    private async validatePassword() {}

    private async validateRelationExtandedForced() {
        await new DynamicFormObjectRelationExtandedForcedValidator({
            formState: this.formState,
            dispatchFormState: this.dispatchFormState,
            popupFormExtendedData: this.popupFormExtendedData,
            fieldRelatedObjectData: this.fieldRelatedObjectData,
            dataRef: this.dataRef
        }).validate();
    }

    private async validateMail() {
        await new DynamicFormObjectMailValidator({
            formState: this.formState,
            dispatchFormState: this.dispatchFormState,
            popupFormExtendedData: this.popupFormExtendedData,
            fieldRelatedObjectData: this.fieldRelatedObjectData,
            dataRef: this.dataRef
        }).validate();
    }

    private async validateDateCorrectness() {
        await new DynamicFormObjectDateCorrectnessValidator({
            formState: this.formState,
            dispatchFormState: this.dispatchFormState,
            popupFormExtendedData: this.popupFormExtendedData,
            fieldRelatedObjectData: this.fieldRelatedObjectData,
            dataRef: this.dataRef
        }).validate();
    }

    private async validateCalendarCollision() {
        await new DynamicFormObjectCalendarCollisionValidator({
            formState: this.formState,
            dispatchFormState: this.dispatchFormState,
            popupFormExtendedData: this.popupFormExtendedData,
            fieldRelatedObjectData: this.fieldRelatedObjectData,
            dataRef: this.dataRef
        }).validate();
    }

    private async validateNIP() {
        await new DynamicFormObjectNIPValidator({
            formState: this.formState,
            dispatchFormState: this.dispatchFormState,
            popupFormExtendedData: this.popupFormExtendedData,
            fieldRelatedObjectData: this.fieldRelatedObjectData,
            dataRef: this.dataRef
        }).validate();
    }

    private async validatePESEL() {
        await new DynamicFormObjectPESELValidator({
            formState: this.formState,
            dispatchFormState: this.dispatchFormState,
            popupFormExtendedData: this.popupFormExtendedData,
            fieldRelatedObjectData: this.fieldRelatedObjectData,
            dataRef: this.dataRef
        }).validate();
    }

    private async default() {}

    private async setTabAndScrollKey() {
        const { activeTab, tabs } = this.popupStateContext;

        if (this.dataRef.validationResult.type.id_zakladki) {
            if (Number(activeTab.id) !== Number(this.dataRef.validationResult.type.id_zakladki)) {
                const tabsKeys = Object.keys(tabs);
                if (
                    tabs.find((item) => item.id === this.dataRef.validationResult.type.id_zakladki)
                ) {
                    const index = tabsKeys.findIndex(
                        (el) => el === this.dataRef.validationResult.type.id_zakladki
                    );
                    this.dispatchPopupStateContext({
                        type: 'SET_ACTIVE_TAB_ID_AND_INDEX_AND_SCROLLKEY',
                        payload: {
                            activeTabId: this.dataRef.validationResult.type.id_zakladki,
                            activeTabIdIndex: index,
                            scrollKey: this.dataRef.validationResult.scrollKey
                        }
                    });
                }
            } else if (this.dataRef.validationResult.scrollKey) {
                this.dispatchPopupStateContext({
                    type: 'SET_SCROLLKEY',
                    payload: {
                        scrollKey: this.dataRef.validationResult.scrollKey
                    }
                });
            }
        }
    }

    private handleResultValidation() {
        const validationResult = this.dataRef.validationResult;
        if (!validationResult.isFormValid) {
            return new Promise<boolean>((res) => {
                switch (validationResult.type.issue) {
                    case 'validateRequirement':
                    case 'validateLogin':
                    case 'validateDate':
                        {
                            const { eventScrollId } = EventHandler.setFieldFocusON({
                                scrollKey: this.dataRef.validationResult.scrollKey,
                                res,
                                dataKey: dataSetInputKey
                            });
                            this.dispatchPopupStateRedux(
                                setPopupInfoTwo({
                                    ...validationResult.type,
                                    closeCallbackEventId: eventScrollId
                                })
                            );
                            this.dataRef.validationResult.breakValidationLoop = true;
                            res(false);
                        }
                        break;
                    case 'validatePESEL':
                    case 'validateNRB':
                    case 'validateMail':
                    case 'validateNIP': {
                        const { eventContiniueId, eventCancelId } =
                            this.prepareFiledPopupWarningCloseListeners(res);

                        this.dispatchPopupStateRedux(
                            setPopupWarning({
                                ...validationResult.type,
                                popupSuperiorKey: this.popupStateRedux.key,
                                sourceOfTrigger: {
                                    type: 'popupFormExtendedValidation',
                                    typeWew: {
                                        name: 'field',
                                        fieldCode: validationResult.type.fieldCode,
                                        cancelCallbackId: eventCancelId,
                                        continiueCallbackId: eventContiniueId
                                    }
                                }
                            })
                        );
                        break;
                    }
                    case 'validateCollision':
                        if (validationResult.typeOfInfo === 'Error') {
                            this.dispatchPopupStateRedux(
                                setPopupInfoTwo({
                                    ...validationResult.type
                                })
                            );
                            this.dataRef.validationResult.breakValidationLoop = true;
                            res(false);
                        } else {
                            const { eventContiniueId, eventCancelId } =
                                this.prepareFiledPopupWarningCloseListeners(res);
                            this.dispatchPopupStateRedux(
                                setPopupWarning({
                                    ...validationResult.type,
                                    popupSuperiorKey: this.popupStateRedux.key,
                                    sourceOfTrigger: {
                                        type: 'popupFormExtendedValidation',
                                        typeWew: {
                                            name: 'field',
                                            fieldCode: validationResult.type.fieldCode,
                                            cancelCallbackId: eventCancelId,
                                            continiueCallbackId: eventContiniueId
                                        }
                                    }
                                })
                            );
                        }
                        break;
                    case 'validateCalendarCollision': {
                        const { eventContiniueId, eventCancelId } =
                            this.prepareFiledPopupWarningCloseListeners(res);
                        this.dispatchPopupStateRedux(
                            setPopupWarning({
                                ...validationResult.type,
                                messageInjectHtml: true,
                                popupSuperiorKey: this.popupStateRedux.key,
                                sourceOfTrigger: {
                                    type: 'popupFormExtendedValidation',
                                    typeWew: {
                                        name: 'fields',
                                        fieldsCode: validationResult.type.fieldsCode,
                                        cancelCallbackId: eventCancelId,
                                        continiueCallbackId: eventContiniueId
                                    }
                                }
                            })
                        );
                        break;
                    }
                    case 'validateRelationExtandedForced':
                    case 'validateFileLoading':
                    case 'validateDateCorrectness':
                    case 'validateInvoiceBill':
                        this.dispatchPopupStateRedux(
                            setPopupInfoTwo({
                                ...validationResult.type,
                                popupSuperiorKey: this.popupStateRedux.key
                            })
                        );
                        this.dataRef.validationResult.breakValidationLoop = true;
                        res(false);
                        break;
                    default:
                        console.error(
                            'DynamicFormObjectGlobalValidator: unknown validationResult issue'
                        );
                        res(false);
                        break;
                }
            });
        }
        return true;
    }

    static validateOneField(formActions: FormActions) {
        switch (formActions.type) {
            case 'tekst':
            case 'lista':
            case 'lista_cena':
            case 'tekst_bez_ograniczen':
            case 'liczba':
            case 'data':
            case 'lista_obca':
            case 'powiazanie_typ':
            case 'koszty':
            case 'lista_zalezna':
            case 'data_wzgledna':
            case 'data_godzina':
            case 'checkbox':
            case 'adres':
            case 'cena':
            case 'data_godziny':
            case 'cena_liczba':
            case 'lista_obca_wielokrotna':
            case 'numer':
            case 'haslo_otwarte':
            case 'numer_rachunku_bankowego':
            case 'plik':
            case 'pliki_wielokrotne':
            case 'pesel':
            case 'nip':
            case 'powiazanie_typ_wielokrotne':
                return {
                    ...formActions,
                    payload: {
                        ...formActions.payload,
                        value: {
                            ...formActions.payload.value,
                            isError: false
                        }
                    }
                };
            default:
                return formActions;
        }
    }

    private prepareFiledPopupWarningCloseListeners = (
        res: (value: boolean) => void
    ): { eventContiniueId: string; eventCancelId: string } => {
        const validationResult = this.dataRef.validationResult;

        const token = generateToken();
        const eventContiniueId = `popupWarningContiniueCallback-${validationResult.type.issue}-${token}`;
        const eventCancelId = `popupWarningCancelCallback-${validationResult.type.issue}-${token}`;

        EventBus.$on(eventContiniueId, () => {
            this.dataRef.validationResult.isFormValid = true;
            EventBus.$off(eventContiniueId);
            EventBus.$off(eventCancelId);
            res(true);
        });
        EventBus.$on(eventCancelId, () => {
            EventBus.$off(eventContiniueId);
            EventBus.$off(eventCancelId);
            this.dataRef.validationResult.breakValidationLoop = true;

            res(false);
        });

        return { eventContiniueId, eventCancelId };
    };
}
