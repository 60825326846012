import { BaseApiResponse } from '../../types';
import { simpleQueryAPIData } from '../simpleQueryAPIData';
import { DataApiMassRejectEditObjects } from 'src/hooks/src/massEditObjectsScreen/useSubmitMassRejectEditObjects';

export async function rejectSaveMassEditObjects(
    data: DataApiMassRejectEditObjects
): Promise<BaseApiResponse<null>> {
    return simpleQueryAPIData(
        'api_react/src/massEditObjectsScreen/rejectSaveMassEditObjects.php',
        'POST',
        data,
        true
    );
}
