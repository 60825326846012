import React, { FC } from 'react';
import { HeadRelationProps } from 'src/components/popupFormExtended/headRelation/types';
import styles from './HeadRelation.module.css';

export const HeadRelation: FC<HeadRelationProps> = (props) => {
    const { relation } = props;

    return (
        <tr>
            {relation?.fields?.map((field, i) => {
                if (field.ukryte === 1) return null;
                return (
                    <td key={i}>
                        <div className={styles.div}>
                            {field.nazwa}
                            <p className={styles.arrow}>
                                {' '}
                                {`${field.obowiazkowe === 't' ? '*' : ''}`}
                            </p>
                        </div>
                    </td>
                );
            })}
            <td></td>
        </tr>
    );
};
