import { combineReducers } from '@reduxjs/toolkit';
import statusTableDataSlice from './src/listScreen/statusData/statusDataSlice';
import treeTableDataSlice from 'src/store/src/listScreen/treeData/treeDataSlice';
import calendarSlice from 'src/store/src/calendar/calendar/calendarSlice';
import categoriesSlice from 'src/store/src/calendar/categories/categoriesSlice';
import resourcesSlice from 'src/store/src/calendar/resources/resourcesSlice';
import userSlice from 'src/store/src/general/user/userSlice';
import systemSlice from 'src/store/src/general/system/systemSlice';
import menuSlice from 'src/store/src/menu/menu/menuSlice';
import popSlice from 'src/store/src/popup/popup/popupSlice';
import transformationsSlice from 'src/store/src/listScreen/transformations/transformationsSlice';
import searchFieldsSlice from 'src/store/src/listScreen/searchFields/searchFieldsSlice';
import tableDataSlice from 'src/store/src/listScreen/tableData/tableDataSlice';
import objectSlice from 'src/store/src/object/object/objectSlice';
import transformationSlice from 'src/store/src/object/tranformation/transformationSlice';
import utilitiesbarSlice from 'src/store/src/utilitiesBar/utilitiesBar/utilitiesbarSlice';
import { appReducer, userReducer, chatsReducer } from 'src/widgets/chat/store/reducers';
import todoReducer from 'src/widgets/todo/store/reducer';
import notesReducer from 'src/widgets/notes/store/reducer';
import alertsSlice from './src/general/alerts/alertsSlice';
import topPlusSlice from './src/menu/topPlusActions/topPlusActionsSlice';
import progressBarSlice from './src/general/progressBar/progressBarSlice';
import configSlice from 'src/store/src/configScreen/config/configSlice';
import messageSlice from 'src/store/src/messageScreen/message/messageSlice';
import importPayoffSlice from 'src/store/src/importPayoff/importPayoff/importPayoffSlice';
import massEditObjectsSlice from 'src/store/src/massEditObjectsScreen/massEditObjects/massEditObjectsSlice';
import objectNotBillSlice from 'src/store/src/objectNotBillScreen/objectNotBill/objectNotBillSlice';
import startupSlice from 'src/store/src/startupScreen/startupScreen/startupSlice';

const rootReducer = combineReducers({
    user: userSlice,
    system: systemSlice,
    alertsData: alertsSlice,
    progressBar: progressBarSlice,
    menu: menuSlice,
    popup: popSlice,
    utilitiesbar: utilitiesbarSlice,
    topPlusSlice: topPlusSlice,
    // calendar
    categories: categoriesSlice,
    calendar: calendarSlice,
    resources: resourcesSlice,
    // list page
    listTransformations: transformationsSlice,
    listSearchFields: searchFieldsSlice,
    listScreenTableData: tableDataSlice,
    listScreenStatusTableData: statusTableDataSlice,
    listTreeTableData: treeTableDataSlice,

    massEditObjects: massEditObjectsSlice,
    // object slice
    object: objectSlice,
    objectTransformation: transformationSlice,
    // widgets
    app: appReducer, // slice integrated for chat widget
    chats: chatsReducer, // slice integrated for chat widget
    chatUser: userReducer, // slice integrated for chat widget
    todo: todoReducer, // slice integrated for todo widget
    notes: notesReducer, // slice integrated for notes widget
    // config
    configScreen: configSlice,
    messageSlice: messageSlice,
    objectNotBill: objectNotBillSlice,
    importPayoffSlice: importPayoffSlice,
    startupScreen: startupSlice
});

export {
    calendarSlice,
    configSlice,
    categoriesSlice,
    resourcesSlice,
    userSlice,
    systemSlice,
    alertsSlice,
    menuSlice,
    popSlice,
    transformationsSlice,
    searchFieldsSlice,
    tableDataSlice,
    statusTableDataSlice,
    objectSlice,
    progressBarSlice,
    startupSlice
};
export default rootReducer;
