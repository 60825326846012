import {
    FieldAdressStateRHF,
    FieldBankAccountNumberStateRHF,
    FieldBasicListManyStateRHF,
    FieldBasicListStateRHF,
    FieldBasicStateRHF,
    FieldCheckboxStateRHF,
    FieldCostsStateRHF,
    FieldDateHourStateRHF,
    FieldDateHoursStateRHF,
    FieldDateStateRHF,
    FieldFileManyStateRHF,
    FieldFileStateRHF,
    FieldListDependentStateRHF,
    FieldListForeignManyStateRHF,
    FieldListForeignStateRHF,
    FieldListStateRHF,
    FieldNipStateRHF,
    FieldNumberSettledToZeroStateRHF,
    FieldNumberStateRHF,
    FieldNumberTwoStateRHF,
    FieldPriceNumberStateRHF,
    FieldPriceStateRHF,
    FieldRelationTypeManyStateRHF,
    FieldRelationTypeStateRHF,
    FieldTekstStateRHF,
    FieldTekstWithPrefixStateRHF,
    FieldTekstWithoutRestrictStateRHF,
    FieldsStateUnionRHF
} from 'src/data/fieldsReactHookForm';
import { DateManager } from 'src/utils';

export function prepareFieldForApiRHF(field: FieldsStateUnionRHF, keyOfField: string) {
    switch (true) {
        case 'powiazanie_typ' in field: {
            const oneObj: Required<FieldRelationTypeStateRHF> =
                field as Required<FieldRelationTypeStateRHF>;
            return {
                [`${keyOfField}`]: oneObj.powiazanie_typ.text,
                [`${keyOfField}_typ`]: oneObj.powiazanie_typ.type,
                [`${keyOfField}_id`]: oneObj.powiazanie_typ.value,
                [`${keyOfField}Select`]: oneObj.powiazanie_typ.value
            };
        }
        case 'powiazanie_typ_wielokrotne' in field: {
            const oneObj: Required<FieldRelationTypeManyStateRHF> =
                field as Required<FieldRelationTypeManyStateRHF>;

            return {
                [`${keyOfField}_nowe`]: oneObj.powiazanie_typ_wielokrotne.objects.map((item) => {
                    return `${item.typ}_${item.id}`;
                }),
                [`${keyOfField}_typ`]: oneObj.powiazanie_typ_wielokrotne.select,
                [`${keyOfField}_usun_pola`]: oneObj.powiazanie_typ_wielokrotne.elements
                    .filter((item) => {
                        if (item.isToDelete) {
                            if (
                                oneObj.powiazanie_typ_wielokrotne.objects.find(
                                    (item2) => item2.id === item.id && item2.typ === item.typ
                                )
                            ) {
                                return false;
                            }
                            return true;
                        }
                        return false;
                    })
                    .map((item) => {
                        return `${item.typ}_${item.id}`;
                    })
            };
        }
        case 'data' in field: {
            const oneObj: Required<FieldDateStateRHF> = field as Required<FieldDateStateRHF>;
            return {
                [keyOfField]: DateManager.prepareDayMonthYearFromDate(oneObj.data)
            };
        }
        case 'data_godzina' in field: {
            const oneObj: Required<FieldDateHourStateRHF> =
                field as Required<FieldDateHourStateRHF>;
            return {
                [keyOfField]: DateManager.prepareDayMonthYearFromDate(oneObj.data_godzina.date),
                [`${keyOfField}Minute`]:
                    oneObj.data_godzina.minute?.length === 1
                        ? '0' + oneObj.data_godzina.minute
                        : oneObj.data_godzina.minute,
                [`${keyOfField}Hour`]:
                    oneObj.data_godzina.hour?.length === 1
                        ? '0' + oneObj.data_godzina.hour
                        : oneObj.data_godzina.hour
            };
        }

        case 'data_godziny' in field: {
            const oneObj: Required<FieldDateHoursStateRHF> =
                field as Required<FieldDateHoursStateRHF>;
            const rozpArr = oneObj.data_godziny.rozp?.split(':');
            const zakArr = oneObj.data_godziny.zak?.split(':');
            return {
                [keyOfField]: DateManager.prepareDayMonthYearFromDate(oneObj.data_godziny.date),
                [`${keyOfField}_rozpMinute`]:
                    rozpArr?.[1]?.length === 1 ? '0' + rozpArr?.[1] : rozpArr?.[1],
                [`${keyOfField}_rozpHour`]:
                    rozpArr?.[0]?.length === 1 ? '0' + rozpArr?.[0] : rozpArr?.[0],
                [`${keyOfField}_zakMinute`]:
                    zakArr?.[1]?.length === 1 ? '0' + zakArr?.[1] : zakArr?.[1],
                [`${keyOfField}_zakHour`]:
                    zakArr?.[0]?.length === 1 ? '0' + zakArr?.[0] : zakArr?.[0]
            };
        }
        case 'cena' in field: {
            const oneObj: Required<FieldPriceStateRHF> = field as Required<FieldPriceStateRHF>;
            return {
                [`${keyOfField}`]: oneObj.cena.netto,
                [`${keyOfField}_brutto`]: oneObj.cena.brutto,
                [`${keyOfField}_vat`]: oneObj.cena.vat,
                [`${keyOfField}_waluta`]: oneObj.cena.waluta
            };
        }
        case 'cena_liczba' in field: {
            const oneObj: Required<FieldPriceNumberStateRHF> =
                field as Required<FieldPriceNumberStateRHF>;
            return {
                [`${keyOfField}`]: oneObj.cena_liczba.netto,
                [`${keyOfField}_brutto`]: oneObj.cena_liczba.brutto,
                [`${keyOfField}_liczba`]: oneObj.cena_liczba.liczba,
                [`${keyOfField}_vat`]: oneObj.cena_liczba.vat,
                [`${keyOfField}_total_brutto`]: oneObj.cena_liczba.total_brutto,
                [`${keyOfField}_total_netto`]: oneObj.cena_liczba.total_netto,
                [`${keyOfField}_waluta`]: oneObj.cena_liczba.waluta
            };
        }
        case 'basic' in field: {
            const oneObj: Required<FieldBasicStateRHF> = field as Required<FieldBasicStateRHF>;
            return {
                [keyOfField]: oneObj.basic
            };
        }
        case 'basic_list' in field: {
            const oneObj: Required<FieldBasicListStateRHF> =
                field as Required<FieldBasicListStateRHF>;
            return {
                [keyOfField]: oneObj.basic_list
            };
        }
        case 'basic_list_many' in field: {
            const oneObj: Required<FieldBasicListManyStateRHF> =
                field as Required<FieldBasicListManyStateRHF>;
            return {
                [keyOfField]: oneObj.basic_list_many.values
                    .filter((el) => el.text !== 'WSZYSTKIE')
                    .map((el) => el.id),
                [`${keyOfField}_wszystkie`]: +(
                    oneObj.basic_list_many.values.findIndex((el) => el.text === 'WSZYSTKIE') !== -1
                )
            };
        }
        case 'tekst_z_przedrostkiem' in field: {
            const oneObj: Required<FieldTekstWithPrefixStateRHF> =
                field as Required<FieldTekstWithPrefixStateRHF>;
            return {
                [keyOfField]: oneObj.tekst_z_przedrostkiem.text,
                [`${keyOfField}_prefix`]: oneObj.tekst_z_przedrostkiem.prefix
            };
        }
        case 'liczba_rozliczana_do_zera' in field: {
            const oneObj: Required<FieldNumberSettledToZeroStateRHF> =
                field as Required<FieldNumberSettledToZeroStateRHF>;
            return {
                [keyOfField]: oneObj.liczba_rozliczana_do_zera.baseValue,
                [`${keyOfField}_rozliczono`]: oneObj.liczba_rozliczana_do_zera.settled,
                [`${keyOfField}_pozostalo`]: oneObj.liczba_rozliczana_do_zera.restToSettle
            };
        }
        case 'adres' in field: {
            const oneObj: Required<FieldAdressStateRHF> = field as Required<FieldAdressStateRHF>;
            return {
                [keyOfField]: oneObj.adres.addressId,
                [`streetType${keyOfField}`]: oneObj.adres.typulicy,
                [`street${keyOfField}`]: oneObj.adres.ulica,
                [`houseNumber${keyOfField}`]: oneObj.adres.nrdomu,
                [`apartmentNumber${keyOfField}`]: oneObj.adres.nrmieszkania,
                [`city${keyOfField}`]: oneObj.adres.miasto,
                [`code${keyOfField}`]: oneObj.adres.kod,
                [`post${keyOfField}`]: oneObj.adres.poczta,
                [`gmina${keyOfField}`]: oneObj.adres.gmina,
                [`powiat${keyOfField}`]: oneObj.adres.powiat,
                [`voivodship${keyOfField}`]: oneObj.adres.wojewodztwo,
                [`country${keyOfField}`]: oneObj.adres.kraj
            };
        }
        case 'tekst' in field: {
            const oneObj: Required<FieldTekstStateRHF> = field as Required<FieldTekstStateRHF>;
            return {
                [keyOfField]: oneObj.tekst
            };
        }
        case 'nip' in field: {
            const oneObj: Required<FieldNipStateRHF> = field as Required<FieldNipStateRHF>;
            return {
                [keyOfField]: oneObj.nip
            };
        }
        case 'numer_rachunku_bankowego' in field: {
            const oneObj: Required<FieldBankAccountNumberStateRHF> =
                field as Required<FieldBankAccountNumberStateRHF>;
            return {
                [keyOfField]: oneObj.numer_rachunku_bankowego
            };
        }
        case 'koszty' in field: {
            const oneObj: Required<FieldCostsStateRHF> = field as Required<FieldCostsStateRHF>;
            return {
                [`${keyOfField}`]: oneObj.koszty.value,
                [`${keyOfField}_waluta`]: oneObj.koszty.currency
            };
        }
        case 'tekst_bez_ograniczen' in field: {
            const oneObj: Required<FieldTekstWithoutRestrictStateRHF> =
                field as Required<FieldTekstWithoutRestrictStateRHF>;
            return {
                [keyOfField]: oneObj.tekst_bez_ograniczen
            };
        }
        case 'checkbox' in field: {
            const oneObj: Required<FieldCheckboxStateRHF> =
                field as Required<FieldCheckboxStateRHF>;
            return {
                [keyOfField]: oneObj.checkbox ? 1 : 0
            };
        }
        case 'lista' in field: {
            const oneObj: Required<FieldListStateRHF> = field as Required<FieldListStateRHF>;
            return {
                [keyOfField]: oneObj.lista.value
            };
        }
        case 'lista_zalezna' in field: {
            const oneObj: Required<FieldListDependentStateRHF> =
                field as Required<FieldListDependentStateRHF>;
            return {
                [keyOfField]: oneObj.lista_zalezna.value
            };
        }
        case 'lista_obca_wielokrotna' in field: {
            const oneObj: Required<FieldListForeignManyStateRHF> =
                field as Required<FieldListForeignManyStateRHF>;
            return {
                [keyOfField]: oneObj.lista_obca_wielokrotna.values
                    .filter((el) => el.text !== 'WSZYSTKIE')
                    .map((el) => el.id),
                [`${keyOfField}_wszystkie`]: +(
                    oneObj.lista_obca_wielokrotna.values.findIndex(
                        (el) => el.text === 'WSZYSTKIE'
                    ) !== -1
                )
            };
        }
        case 'liczba' in field: {
            const oneObj: Required<FieldNumberStateRHF> = field as Required<FieldNumberStateRHF>;
            return {
                [keyOfField]: oneObj.liczba
            };
        }
        case 'lista_obca' in field: {
            const oneObj: Required<FieldListForeignStateRHF> =
                field as Required<FieldListForeignStateRHF>;
            return {
                [`${keyOfField}`]: oneObj.lista_obca.value,
                [`${keyOfField}_tekst`]: oneObj.lista_obca.text
            };
        }
        case 'plik' in field: {
            const oneObj: Required<FieldFileStateRHF> = field as Required<FieldFileStateRHF>;
            return { [keyOfField]: { id: oneObj.plik.id, name: oneObj.plik.name } };
        }
        case 'plik_wielokrotne' in field: {
            const oneObj: Required<FieldFileManyStateRHF> =
                field as Required<FieldFileManyStateRHF>;
            return {
                [keyOfField]: oneObj.pliki_wielokrotne.map((f) => ({
                    id: f.id,
                    name: f.name
                }))
            };
        }
        case 'numer' in field: {
            const oneObj: Required<FieldNumberTwoStateRHF> =
                field as Required<FieldNumberTwoStateRHF>;
            return {
                [`${keyOfField}_interfix`]: oneObj.number.interfix,
                [`${keyOfField}_prefix`]: oneObj.number.prefix,
                [`${keyOfField}_suffix`]: oneObj.number.suffix
            };
        }
        default:
            break;
    }
}
