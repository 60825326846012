import React from 'react';
import { useNavigate } from 'react-router-dom';

import { rejectSaveMassEditObjects } from 'src/api/src/massEditObjectsScreen/rejectSaveMassEditObjects';
import { useSearchParam } from 'src/hooks/src/shared/useSearchParam';
import { useTypedSelector } from 'src/store';

export type DataApiMassRejectEditObjects = {
    obiekt: string;
    editElements: string[];
};

export const useSubmitMassRejectEditObjects = () => {
    const massEditObjects = useTypedSelector((state) => state.massEditObjects);
    const navigate = useNavigate();
    const obiekt = useSearchParam('obiekt');
    const editElements = React.useMemo(() => {
        return massEditObjects.row.filter((item) => !item.locked).map((row) => row.id);
    }, [massEditObjects.row]);

    const onClick = async () => {
        const data = {
            obiekt: obiekt ?? '',
            editElements
        };
        const res = await rejectSaveMassEditObjects(data);
        if (res.success) {
            navigate(-1);
        }
    };

    return onClick;
};
