import React, { FC } from 'react';
import { useRunAllHooksOnEachRenderReactPage } from 'src/hooks';

const HelpView = React.lazy(
    () =>
        import(
            /* webpackChunkName: "ObjectView" */ 'src/components/helpScreen/templates/helpView/HelpView'
        )
);

export const HelpMain: FC = () => {
    useRunAllHooksOnEachRenderReactPage('Pomoc');
    return (
        <div>
            <React.Suspense fallback>
                <HelpView />
            </React.Suspense>
        </div>
    );
};
