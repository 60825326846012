import { FieldListPriceMany } from './../../../dynamicField/fieldListPriceMany/types.d';
import {
    FieldDependentDefaultManager,
    FieldDependentDefaultManagerProps
} from 'src/components/popupFormExtended/fieldGlobalDependent/fieldsDependentManager/fieldDependentDefaultManager/FieldDependentDefaultManager';
import { FieldListForeignMany } from 'src/components/popupFormExtended/dynamicField/fieldListForeignMany/types';
import { FieldListMany } from 'src/components/popupFormExtended/dynamicField/fieldListMany/types';

type FieldDependentListForeignManyManagerProps = Omit<
    FieldDependentDefaultManagerProps,
    'fieldWasTriggered'
> & {
    fieldWasTriggered: FieldListForeignMany | FieldListMany | FieldListPriceMany;
};

export class FieldDependentListForeignManyManager extends FieldDependentDefaultManager {
    fieldWasTriggered: FieldListForeignMany | FieldListMany | FieldListPriceMany;

    constructor({
        formState,
        popupFormExtendedData,
        tempFieldsState,
        fieldWasTriggered,
        dataFunctionDynamic,
        relationId,
        prefix
    }: FieldDependentListForeignManyManagerProps) {
        super({
            formState,
            popupFormExtendedData,
            tempFieldsState,
            fieldWasTriggered: fieldWasTriggered,
            dataFunctionDynamic,
            relationId,
            prefix
        });
        this.fieldWasTriggered = fieldWasTriggered;
    }

    async zmienPole() {
        if (!this.fieldWasTriggered.isUpdatedDepedentent) {
            await this.checkAndChangeFieldChangeStateOfField();
            await this.runNested(this.fieldWasTriggeredDynamicFieldT);
        }
    }

    private async checkAndChangeFieldChangeStateOfField() {
        let opcje = this.fieldWasTriggered.value.opcje;

        const data = await this.getValueToSetForChangeField();
        opcje = [{ id: '0', text: 'WSZYSTKIE' }, ...data.optionAutoComplete];
        const values = this.fieldWasTriggered.value.values.filter((item) => {
            if (opcje.find((item2) => item2.text === item.text)) {
                return true;
            }
            return false;
        });
        const newValue = {
            ...this.fieldWasTriggered,
            value: {
                opcje,
                values
            }
        };
        this.setTempFieldsState(newValue, 'isUpdatedDepedentent');
    }
}
