import React, { FC } from 'react';
import { useTheme } from '@mui/material/styles';

import { DatePicker, GetDate } from 'src/components/shared/atoms/datePicker/DatePicker';
import { DynamicDateHourProps } from 'src/components/shared/atoms/dynamicDateHour/types';
import style from './style.module.css';
import { InputTime } from 'src/components/shared/atoms/datePicker/InputTime';

export const DynamicDateHour: FC<DynamicDateHourProps> = ({
    dynamicFieldT,
    field,
    isDisabledLabel,
    isInputTimeInDatePicker,
    isCheckboxTodayInDatePicker,
    fieldValue
}) => {
    const theme = useTheme();
    const ubdateDate = (data: GetDate) => {
        field.onChange({
            ...fieldValue,
            value: {
                ...fieldValue.value,
                date: data.dateSelectedNormal
            }
        });
    };

    return (
        <div
            className={style.wrapper}
            style={{
                gridTemplateColumns: `${isInputTimeInDatePicker ? '60% 40%' : '100%'}`
            }}>
            <DatePicker
                getDate={ubdateDate}
                labelDate={dynamicFieldT.nazwa}
                numberPanels={'three'}
                dateInitial={fieldValue.value.date ?? new Date()}
                isCheckboxToday={isCheckboxTodayInDatePicker}
                dateFromOuterToUpdate={fieldValue.value.date}
                sxContainerPanels={{ position: 'fixed', transform: 'translateY(10%)' }}
                isDisabledLabel={isDisabledLabel}
                sxUiContainerInputsNormal={{
                    gridTemplateColumns: 'auto auto'
                }}
                portalEnabled={true}
            />

            {isInputTimeInDatePicker && (
                <InputTime
                    label="Godzina"
                    type={'text'}
                    placeholder="gg:mm"
                    sxinputtime={{ color: theme.palette.primary.main }}
                    maskChar={''}
                    value={fieldValue.value.hour || ''}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        field.onChange({
                            ...fieldValue,
                            value: {
                                ...fieldValue.value,
                                hour: e.target.value
                            }
                        });
                    }}
                />
            )}
        </div>
    );
};
