import React, { FC } from 'react';
import { ArrowProps } from './types';
import { useTheme } from '@mui/material/styles';
import styles from './Arrow.module.css';

const Arrow: FC<ArrowProps> = ({ isUnFolded, style }) => {
    const theme = useTheme();
    return (
        <div
            className={`${styles.arrow} ${isUnFolded ? styles.up : styles.down} ${
                !isUnFolded && styles.arrow_down
            }`}
            style={{
                border: `solid ${theme.palette.primary.main}`,
                borderWidth: '0 2px 2px 0',
                ...style
            }}></div>
    );
};

export default Arrow;
