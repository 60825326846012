import { UseFormGetValues } from 'react-hook-form';
import { FieldsStateRHF } from 'src/data/fieldsReactHookForm';

export function updateKeyResetRHF({
    getValues,
    key
}: {
    key: string;
    getValues: UseFormGetValues<FieldsStateRHF>;
}) {
    const stateBefore = getValues(key);
    const keyReset = stateBefore?.keyReset ?? 1;
    return keyReset + 2;
}
