import { useTheme } from '@mui/material/styles';

export function useStatColorsStartupScreen() {
    const theme = useTheme();
    return {
        systemColor: theme.palette.primary.main,
        systemColorTwo: 'rgb(27, 117, 188)',
        primary: 'rgb(255, 201, 14)',
        secondary: 'rgb(255, 127, 39)',
        third: 'rgb(20, 90, 50)'
    };
}
