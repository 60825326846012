import store from '../../index';

export default store;

export type { AppDispatch, AppThunk, RootState } from '../../index';

export { systemSlice, userSlice } from '../../rootReducer';

export {
    setMenuStructure,
    setNewMessgesCount,
    ubdateModule,
    ubdateModuleUnhold,
    updateMenuPins,
    toggleUnfolded
} from './menu/menuSlice';

export { setTopPlusActions } from './topPlusActions/topPlusActionsSlice';
