import React, { FC } from 'react';

import { useTypedSelector } from 'src/store';
import { StartupAnimationProps } from './types';
import MecenasLogo from 'src/assets/logo/MECENASpion.svg';
import styles from './styles.module.css';

export const StartupAnimation: FC<StartupAnimationProps> = () => {
    const width = useTypedSelector((state) => state.menu.menuWidth);

    return (
        <MecenasLogo
            className={styles.wrapper}
            style={{
                width: `calc(100vw - ${width}px`
            }}
        />
    );
};
