import {
    FieldDependentDefaultManager,
    FieldDependentDefaultManagerProps
} from 'src/components/popupFormExtended/fieldGlobalDependent/fieldsDependentManager/fieldDependentDefaultManager/FieldDependentDefaultManager';
import { FieldList } from 'src/components/popupFormExtended/dynamicField/fieldList/types';
import { FieldListPrice } from 'src/components/popupFormExtended/dynamicField/fieldListPrice/types';

type FieldDependentListManagerProps = Omit<
    FieldDependentDefaultManagerProps,
    'fieldWasTriggered'
> & {
    fieldWasTriggered: FieldList | FieldListPrice;
};

export class FieldDependentListManager extends FieldDependentDefaultManager {
    fieldWasTriggered: FieldList | FieldListPrice;

    constructor({
        formState,
        popupFormExtendedData,
        tempFieldsState,
        fieldWasTriggered,
        dataFunctionDynamic,
        relationId,
        prefix
    }: FieldDependentListManagerProps) {
        super({
            formState,
            popupFormExtendedData,
            tempFieldsState,
            fieldWasTriggered: fieldWasTriggered,
            dataFunctionDynamic,
            relationId,
            prefix
        });
        this.fieldWasTriggered = fieldWasTriggered;
    }

    async zmienPole() {
        if (!this.fieldWasTriggered.isUpdatedDepedentent) {
            await this.checkAndChangeFieldChangeStateOfField();
            await this.runNested(this.fieldWasTriggeredDynamicFieldT);
        }
    }

    private async checkAndChangeFieldChangeStateOfField() {
        let opcje = this.fieldWasTriggered.value.opcje;

        const data = await this.getValueToSetForChangeField();
        opcje = data.opcje;
        const value = Object.keys(opcje).includes(this.fieldWasTriggered.value.value ?? '')
            ? this.fieldWasTriggered.value.value
            : '';
        const newValue = {
            ...this.fieldWasTriggered,
            value: {
                ...this.fieldWasTriggered.value,
                opcje,
                value
            }
        };
        this.isRunNested = true;
        this.setTempFieldsState(newValue, 'isUpdatedDepedentent');
    }
}
