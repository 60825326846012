import { AppDispatch } from 'src/store';
import {
    setPopupWithoutData,
    setPopup,
    setPopupPrompt as setPromptPopup
} from 'src/store/src/popup';
import { SystemAction, PopupBehavior, ApiActionBehavior, Optional } from 'src/data/types';
import { RowDataId } from 'src/store/src/listScreen/tableData/types';
import { SourceOfTrigger } from 'src/data/sourceOfTrigger';
import { Additional } from 'src/store/src/object/object/types';
import { PopupFormData, PopupFormExtendedData } from 'src/data/popupFormTypes';
import { PopupPromptPayloads } from './types';
import { globalPopup } from 'src/constants/subtitles';
import { convertPopupFormExtendedDataFromApi } from 'src/utils';
// PopupManager obsługe slice popup w redux, czyli w praktyce ustawia popup z danymi lub bez
// PopupManager menage slice popup in redux, in practice set popup with or without data

type PopupManagerProps = {
    dispatch: AppDispatch;
    action: SystemAction | null;
};

export class PopupManager {
    dispatch: AppDispatch;
    action: SystemAction | null;

    constructor({ dispatch, action }: PopupManagerProps) {
        this.dispatch = dispatch;
        this.action = action;
    }

    setPopupInfo(message: string) {
        this.dispatch(
            setPopup({
                type: 'info',
                message: message
            })
        );
    }

    setPopupTemplate({
        behaviour,
        rowId,
        sourceOfTrigger,
        additional,
        closeCallbackEventId
    }: {
        behaviour: PopupBehavior;
        rowId: RowDataId | null;
        sourceOfTrigger?: SourceOfTrigger;
        additional?: Additional;
        closeCallbackEventId?: string;
    }) {
        if (behaviour.data.template === 'preview') {
            this.dispatch(
                setPopup({
                    type: behaviour?.data?.template,
                    objectId: rowId ? [rowId] : [],
                    action: this.action,
                    sourceOfTrigger,
                    objectAdditional: additional
                })
            );
        } else if (behaviour.data.template === 'changePassword') {
            this.dispatch(
                setPopup({
                    type: behaviour?.data?.template,
                    action: this.action,
                    sourceOfTrigger,
                    objectAdditional: additional,
                    isPossibleClose: !behaviour.data.data.lockExit,
                    closeCallbackEventId: closeCallbackEventId
                })
            );
        } else {
            this.dispatch(
                setPopupWithoutData({
                    type: behaviour?.data?.template
                })
            );
        }
    }

    setPopupDynamic({
        rowsId,
        title,
        sourceOfTrigger,
        additional,
        popupFormData
    }: {
        rowsId: RowDataId[];
        title: string;
        sourceOfTrigger?: SourceOfTrigger;
        additional?: Additional;
        popupFormData: PopupFormData;
    }) {
        this.dispatch(
            setPopup({
                type: 'dynamic',
                action: this.action,
                objectId: rowsId,
                title: title,
                sourceOfTrigger,
                objectAdditional: additional,
                popupFormData: popupFormData
            })
        );
    }

    setPopupDynamicExtended({
        popupFormExtendedData
    }: {
        popupFormExtendedData?: PopupFormExtendedData;
    }) {
        this.dispatch(
            setPopup({
                type: 'dynamicExtended',
                action: this.action,
                popupFormExtendedData: convertPopupFormExtendedDataFromApi(popupFormExtendedData)
            })
        );
    }

    setPopupConfirmLink({
        rowId,
        sourceOfTrigger,
        additional
    }: {
        rowId: RowDataId[];
        sourceOfTrigger?: SourceOfTrigger;
        additional?: Additional;
    }) {
        this.dispatch(
            setPopup({
                type: 'confirmationLink',
                objectId: rowId,
                action: this.action,
                sourceOfTrigger,
                objectAdditional: additional
            })
        );
    }

    setPopupConfirmApi({
        rowId,
        behaviour,
        sourceOfTrigger,
        additional,
        closeCallbackEventId
    }: {
        rowId: RowDataId[];
        behaviour: ApiActionBehavior;
        sourceOfTrigger?: SourceOfTrigger;
        additional?: Additional;
        closeCallbackEventId?: string;
    }) {
        if (behaviour?.data?.secondConfirmText) {
            this.dispatch(
                setPopup({
                    type: 'initialConfirmationApi',
                    objectId: rowId,
                    action: this.action,
                    sourceOfTrigger,
                    objectAdditional: additional,
                    closeCallbackEventId
                })
            );
        } else {
            this.dispatch(
                setPopup({
                    type: 'confirmationApi',
                    objectId: rowId,
                    action: this.action,
                    sourceOfTrigger,
                    objectAdditional: additional,
                    closeCallbackEventId
                })
            );
        }
    }

    setPopupPrompt(payload: Optional<PopupPromptPayloads, 'title' | 'focusText'>) {
        this.dispatch(
            setPromptPopup({
                ...payload,
                title: payload.title ?? globalPopup.contentPrompt.title,
                focusText: payload.focusText ?? false
            })
        );
    }
}
