import React, { FC } from 'react';
import { Button } from '@mui/material';

import PopupTemplateThird from 'src/components/shared/organisms/popupTemplateThird/PopupTemplateThird';
import { useAppDispatch, useTypedSelector } from 'src/store';
import { closePopup } from 'src/store/src/popup';
import { globalPopup } from 'src/constants/subtitles';
import { BasePopupContentProps } from 'src/components/shared/organisms/globalPopup/GlobalPopup';
import styles from './PopupContentConfigOrder.module.css';
import { ApiRelationCardsOrder } from 'src/api/src/object/types';
import { updateRelationCardsOrder } from 'src/api/src/object/updateRelationCardsOrder';
import { usePressKeyWithKeyCode, useSearchParam } from 'src/hooks';
import { RelationCardOrderInfo, RelationCardsOrder } from './types';
import { changeRelationsOrder } from 'src/store/src/object';
import { useRefreshObjectData } from 'src/hooks/src/object/useRefreshObjectData';
import { UiConfigOrderListsWrapper } from 'src/components/shared/atoms/uiLayout/uiConfigOrderListsWrapper/UiConfigOrderListsWrapper';
import { UiConfigOrderList } from '../../atoms/uiLayout/uiConfigOrderList/UiConfigOrderList';

export const PopupContentConfigOrder: FC<BasePopupContentProps> = ({ popup }) => {
    const activeTabData = useTypedSelector((state) => state.object.activeTabData);
    const screen = useSearchParam('obiekt');
    const refreshObjectScreenData = useRefreshObjectData();
    const dispatch = useAppDispatch();
    const [cardsOrder, setCardsOrder] = React.useState<RelationCardsOrder>({});
    const refInitialData = React.useRef({});

    const update = (
        cardClicked: RelationCardOrderInfo,
        change: 'up' | 'down',
        sectionId: string
    ) => {
        const cards = cardsOrder[sectionId];
        const cardClickedIndex = cards.indexOf(cardClicked);
        const newCards: RelationCardOrderInfo[] = cards.map((card) => card);
        if (change === 'up') {
            const cardBefore = cards[cardClickedIndex - 1];
            newCards[cardClickedIndex - 1] = cardClicked;
            newCards[cardClickedIndex] = cardBefore;
        } else if (change === 'down') {
            const cardAfter = cards[cardClickedIndex + 1];
            newCards[cardClickedIndex + 1] = cardClicked;
            newCards[cardClickedIndex] = cardAfter;
        }
        setCardsOrder((prev) => {
            return {
                ...prev,
                [sectionId]: newCards
            };
        });
    };

    const save = async () => {
        if (!screen) {
            console.error("PopupContentConfigOrder: can't get screen param");
            return;
        }

        // update store

        dispatch(changeRelationsOrder(cardsOrder));

        // make data for api

        let cards: RelationCardOrderInfo[] = [];

        for (const key in cardsOrder) {
            cards = [...cards, ...cardsOrder[key]];
        }

        const orderTable: ApiRelationCardsOrder[] = cards.map((card, i) => ({
            relationId: card.id,
            order: i
        }));

        const response = await updateRelationCardsOrder(screen, orderTable);

        if (!response.success) {
            dispatch(changeRelationsOrder(refInitialData.current));
        } else {
            dispatch(closePopup(popup.key));
            refreshObjectScreenData(null);
        }
    };

    React.useEffect(() => {
        if (activeTabData) {
            const data: RelationCardsOrder = {};
            for (const section of activeTabData?.sections) {
                if (section?.relationDisplayMode === 'karta' && section?.relations?.length) {
                    data[section.id] = section.relations.map((item) => {
                        return {
                            id: item.id,
                            title: item.title
                        };
                    });
                }
            }
            // setData to handle
            setCardsOrder(data);
            // data for remember if api success false
            refInitialData.current = data;
        }
    }, []);
    usePressKeyWithKeyCode(save, 'Enter');
    return (
        <PopupTemplateThird title={'Konfiguracja kolejności'}>
            <UiConfigOrderListsWrapper>
                {Object.entries(cardsOrder).map(([id, cards], key) => {
                    return (
                        <UiConfigOrderList
                            key={key}
                            data={cards}
                            onArrowClick={(item, direction) => update(item, direction, id)}
                            withCheckboxes={false}
                        />
                    );
                })}
                <div className={styles.box}>
                    <Button
                        variant="contained"
                        sx={{
                            color: (theme) => theme.palette.background.default,
                            padding: '6px 40px',
                            cursor: 'pointer'
                        }}
                        onClick={save}>
                        <p>{globalPopup.contentInfo.button.yes}</p>
                    </Button>
                </div>
            </UiConfigOrderListsWrapper>
        </PopupTemplateThird>
    );
};
