import React, { FC } from 'react';

import { UiFieldLabelProps } from './types';
import { useTheme } from '@mui/material/styles';
import styles from './UiFieldLabel.module.css';

export const UiFieldLabel: FC<UiFieldLabelProps> = ({
    name,
    isLabel,
    isMandatory,
    isError,
    dynamicFieldT
}) => {
    const theme = useTheme();
    if (!isLabel) return null;
    const label = name.length < 60 ? name : name.substring(0, 60) + '...';
    return (
        <div
            className={styles.wrapper}
            style={{
                color: `${isError ? theme.palette.error.main : theme.palette.secondary.main}`
            }}>
            <p
                style={{
                    fontWeight: `${
                        dynamicFieldT.pogrubienie_ekran_obiektu === 't' ? 'bold' : 'normal'
                    }`
                }}>{`${label}${isMandatory ? '*' : ''}`}</p>
        </div>
    );
};
