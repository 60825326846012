import * as React from 'react';

import { DynamicFieldT } from 'src/data/popupFormTypes';

import {
    FormState,
    SumsRelationship,
    DynamicFormObjectFieldsSetterProps,
    DynamicFormObjectFieldsSetterUpdatedFormStateResponse,
    FormStateDispatch
} from 'src/components/popupFormExtended/dynamicFormObject/types';
import { PopupFormExtendedData } from 'src/data/popupFormTypes';
import { Fields } from 'src/components/popupFormExtended/dynamicField/types';
import {
    TempFieldsState,
    TempFieldsProperties,
    TempFieldsValues
} from 'src/components/popupFormExtended/fieldGlobalDependent/types';
import { FieldGlobalDependentManager } from 'src/components/popupFormExtended/fieldGlobalDependent/FieldGlobalDependentManager';
import { SumsCalcManager } from 'src/components/popupFormExtended/fieldGlobalDependent/sumsCalcManager/SumsCalcManager';
import { consoleConditionally } from 'src/utils';

export class DynamicFormObjectFieldsSetter {
    formState: FormState;
    dynamicFieldT: DynamicFieldT | null;
    fieldState: Fields;
    popupFormExtendedData: PopupFormExtendedData;
    usedForAddingObject: boolean;
    tempFieldsState: TempFieldsState;
    tempFieldsValues: TempFieldsValues;
    tempFieldsProperties: TempFieldsProperties;
    fieldsToDispatch:
        | {
              [key: string]: Fields;
          }
        | {};
    prefix?: string;
    relationId?: string;
    sums: SumsRelationship;
    constructor(props: DynamicFormObjectFieldsSetterProps) {
        this.formState = props.formState;
        this.dynamicFieldT = props.dynamicFieldT ? props.dynamicFieldT : null;
        this.fieldState = props.fieldState ? props.fieldState : null;
        this.popupFormExtendedData = props.popupFormExtendedData;
        this.usedForAddingObject = props.popupFormExtendedData.objectId === 'nowy';
        this.prefix = props.prefix;
        this.relationId = props.relationId;
        this.tempFieldsState = {};
        this.fieldsToDispatch = {};
        this.sums = {};
        this.tempFieldsValues = {
            sums: {
                value: ''
            }
        };
        this.tempFieldsProperties = {
            defaultValues: {}
        };
        this.setFirstTempField();
    }

    private async setFirstTempField() {
        // set state of change field value/state which user updated before updates will be showed in formState
        if (this.fieldState) {
            this.tempFieldsState = {
                [this.fieldState.fieldKey]: this.fieldState
            };
        }
    }

    // clear temp flag for field was updated
    private clearFlag() {
        const data: { [key: string]: Fields | {} } = {};
        consoleConditionally(this.tempFieldsState, 'Clear Flag');

        for (const key in this.tempFieldsState) {
            data[key] = {
                ...this.tempFieldsState[key],
                isUpdatedCalc: false,
                isUpdatedDepedentent: false,
                isUpdatedDepedententDefault: false,
                isUpdatedVisibility: false,
                isUpdatedValueFromSuperior: false
            };
        }

        this.fieldsToDispatch = data;
    }

    // dependent calculations made on form mount

    async executeAllOnMount(dispatch: FormStateDispatch) {
        await new FieldGlobalDependentManager({
            formState: this.formState,
            tempFieldsState: this.tempFieldsState,
            popupFormExtendedData: this.popupFormExtendedData,
            tempFieldsValues: this.tempFieldsValues
        }).executeOnMount();
        this.updateFields(dispatch);
    }

    // updating fields by dispatch

    private updateFields(dispatch: FormStateDispatch) {
        this.clearFlag();
        this.dispatchToState(dispatch);
    }

    private dispatchToState(dispatch: FormStateDispatch) {
        let isDispatch = true;
        if (this.dynamicFieldT) {
            //update state when any field was updated
            const keys = Object.keys(this.fieldsToDispatch);
            const updatedKeys = keys.filter((item) => item !== this.dynamicFieldT?.kod);
            if (!updatedKeys.length) isDispatch = false;
        }

        if (isDispatch) {
            if (Object.keys(this.sums).length) {
                dispatch({
                    type: 'update_fields_and_sums',
                    payload: {
                        fields: this.fieldsToDispatch,
                        sums: this.sums
                    }
                });
            } else {
                dispatch({
                    type: 'update_fields',
                    payload: this.fieldsToDispatch
                });
            }
        } else {
            if (Object.keys(this.sums).length) {
                dispatch({
                    type: 'sums_relationship',
                    payload: this.sums
                });
            }
        }
    }

    // dependent calculations when some field value changes

    async executeOne(
        keyOfFieldInState: string
    ): Promise<DynamicFormObjectFieldsSetterUpdatedFormStateResponse> {
        //execute when user interact
        if (!this.dynamicFieldT) {
            return { changed: false };
        }

        this.setToTempStateFieldTrigger(keyOfFieldInState);
        await new FieldGlobalDependentManager({
            formState: this.formState,
            tempFieldsState: this.tempFieldsState,
            popupFormExtendedData: this.popupFormExtendedData,
            tempFieldsValues: this.tempFieldsValues,
            prefix: this.prefix,
            relationId: this.relationId
        }).executeOne(this.dynamicFieldT);
        await new SumsCalcManager({
            formState: this.formState,
            tempFieldsState: this.tempFieldsState,
            tempFieldsValues: this.tempFieldsValues,
            sums: this.sums
        }).runFieldsWasChanged();

        /**
         * Here is explanation how zmienWartosciPolZNadrzednegoSumsForAllChanged works
         * Overall zmienWartosciPolZNadrzednegoSums is responsible for updating with parent field sum, child field targeted by
         * parent pole_z_ekranu_do_przeniesienia_sumy property
         * This system is probably only used when parent field is in relation, and child outside of relation, and we want to move relation
         * column(parent) sum to some field(child)
         * zmienWartosciPolZNadrzednegoSumsForAllChanged fire zmienWartosciPolZNadrzednegoSums for all fields changed in this dependent calculation,
         * so every field that change value will try to move updated sum(every field wille have called zmienWartosciPolZNadrzednegoSums checking if
         * that sum move is needed)
         */
        await new FieldGlobalDependentManager({
            formState: this.formState,
            tempFieldsState: this.tempFieldsState,
            popupFormExtendedData: this.popupFormExtendedData,
            tempFieldsValues: this.tempFieldsValues,
            prefix: this.prefix,
            relationId: this.relationId
        }).zmienWartosciPolZNadrzednegoSumsForAllChanged();
        return this.getUpdatedFields();
    }

    private setToTempStateFieldTrigger(keyOfFieldInState: string) {
        this.tempFieldsState[keyOfFieldInState] = {
            ...this.fieldState
        };
        if (this.tempFieldsState[keyOfFieldInState]) {
            this.tempFieldsState[keyOfFieldInState]!.isUpdatedCalc = true;
            this.tempFieldsState[keyOfFieldInState]!.isUpdatedDepedentent = true;
            this.tempFieldsState[keyOfFieldInState]!.isUpdatedDepedententDefault = true;
            this.tempFieldsState[keyOfFieldInState]!.isUpdatedValueFromSuperior = true;
            this.tempFieldsState[keyOfFieldInState]!.isUpdatedVisibility = true;
        }
    }

    // updating fields by dispatch returning to store

    private getUpdatedFields(): DynamicFormObjectFieldsSetterUpdatedFormStateResponse {
        this.clearFlag();
        return this.getUpadedFormState();
    }

    private getUpadedFormState(): DynamicFormObjectFieldsSetterUpdatedFormStateResponse {
        let isDispatch = true;
        if (this.dynamicFieldT) {
            //update state when any field was updated
            const keys = Object.keys(this.fieldsToDispatch);
            const updatedKeys = keys.filter((item) => item !== this.dynamicFieldT?.kod);
            if (!updatedKeys.length) isDispatch = false;
        }

        if (!isDispatch && !Object.keys(this.sums).length) {
            return { changed: false };
        }

        return {
            changed: true,
            newState: {
                ...this.formState,
                fields: {
                    ...this.formState.fields,
                    ...this.fieldsToDispatch
                },
                sumsRelationship: {
                    ...this.formState.sumsRelationship,
                    ...this.sums
                }
            }
        };
    }
}
