import React, { FC } from 'react';
import { getNeededFieldParamsToUiFieldCommonPropsFull } from 'src/utils/src/popupFormExtended/getNeededFieldParamsToUiFieldCommonPropsFull';
import {
    FieldListForeignAutocompleteOption,
    FieldListForeignObjectProps,
    FieldListForeignOptions
} from './types';
import { DynamicFormObjectDataConverter } from 'src/components/popupFormExtended/dynamicFormObject/DynamicFormObjectDataConverter';
import { UIFieldListForeign } from 'src/components/popupFormExtended/dynamicField/fieldListForeign/UiFieldListForeign';
import { BroadcastAddBackPayload } from '../../types';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { DynamicFormObjectStateHandler } from 'src/components/popupFormExtended/dynamicFormObject/DynamicFormObjectStateHandler';
import {
    clearTextFromApiForOptionAutocomplete,
    createSelectOptions,
    generateAddBackUrl
} from 'src/utils';
import { getFieldAdditionalData } from 'src/api/src/shared/getFieldAdditionalData';
import { getTypeUrl } from 'src/utils';
import { useAddBackBroadcastChannel } from 'src/hooks/src/popupFormExtended/useAddBackBroadcastChannel';
import { dataSetInputKey } from 'src/constants/dataset';
import { doDependentCalculationsAfterFieldChanged } from '../../dynamicFormObject/doDependentCalculationsAfterFieldChanged';
import { FieldListForeignBasicTypeValue } from 'src/data/fieldsFormType';

const filter = createFilterOptions<FieldListForeignAutocompleteOption>();

export const FieldListForeignObject: FC<FieldListForeignObjectProps> = ({
    field,
    keyOfFieldInState,
    dynamicFieldT,
    dispatch,
    uiFieldCommonProps,
    popupFormExtendedData,
    prefix,
    relationId,
    fieldSuperior
}) => {
    const [inputValue, setInputValue] = React.useState(field.value.text);
    const [autocompleteOptions, setAutocompleteOptions] = React.useState<FieldListForeignOptions>(
        []
    );

    const startAddBackBroadcastChannel = useAddBackBroadcastChannel(dynamicFieldT.kod);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === 'nowy') {
            handleAddNewObject();
            // to prevent have 'nowy' value in field
            return;
        }
        setFieldValue({
            ...field.value,
            value: e.target.value,
            text: field.value.opcje[e.target.value]
        });
    };

    const onChangeAutocomplete = (
        _: React.SyntheticEvent<Element, Event>,
        newValue: { id: string; text: string }
    ) => {
        if (newValue) {
            if (newValue.id === 'nowy') {
                handleAddNewObject();
                // to prevent have 'nowy' value in field
                return;
            }
            setFieldValue({
                ...field.value,
                value: newValue.id,
                text: newValue.text
            });
        } else {
            const newVal = {
                ...field,
                value: {
                    ...field.value,
                    value: '',
                    text: ''
                }
            };
            dispatch({
                type: 'lista_obca',
                payload: {
                    code: keyOfFieldInState,
                    value: newVal
                }
            });
            dispatch(
                doDependentCalculationsAfterFieldChanged({
                    fieldState: newVal,
                    dynamicFieldT: dynamicFieldT,
                    popupFormExtendedData,
                    prefix,
                    relationId,
                    keyOfFieldInState
                })
            );
        }
    };

    const onBlur = (e: React.FocusEvent<any>) => {};

    const setFieldValue = (newValue: FieldListForeignBasicTypeValue) => {
        const newField = {
            ...field,
            ...DynamicFormObjectStateHandler.overrideFieldPropertiesOnChange(),
            value: newValue
        };
        dispatch({
            type: 'lista_obca',
            payload: {
                code: keyOfFieldInState,
                value: newField
            }
        });
        dispatch(
            doDependentCalculationsAfterFieldChanged({
                fieldState: newField,
                dynamicFieldT: dynamicFieldT,
                popupFormExtendedData,
                prefix,
                relationId,
                keyOfFieldInState
            })
        );
    };

    const getOptionsFromApi = (value: string) => {
        let valueSuperior: string | null = '';
        switch (fieldSuperior?.typ) {
            case 'lista_obca':
            case 'powiazanie_typ':
                valueSuperior = fieldSuperior.value.value;
                break;
            default:
                break;
        }
        let params2 = {};
        if (dynamicFieldT.pole_nadrzedne_typ) {
            params2 =
                dynamicFieldT.pole_nadrzedne_typ === 'lista_obca'
                    ? {
                          q: valueSuperior,
                          typ_nadrzedne: dynamicFieldT.pole_nadrzedne_tabela_opcji,
                          typ_podrzedne: dynamicFieldT.tabela_opcji,
                          id_pola: dynamicFieldT.id,
                          filtr: value
                      }
                    : {
                          q: valueSuperior,
                          typ_nadrzedne: dynamicFieldT.pole_nadrzedne_typ,
                          typ_podrzedne: dynamicFieldT.tabela_opcji,
                          id_pola: dynamicFieldT.id,
                          filtr: value
                      };
        } else {
            params2 = {
                q: value,
                typ: dynamicFieldT.tabela_opcji,
                id_pola: dynamicFieldT.id
            };
        }
        const url = getTypeUrl({ field: dynamicFieldT });

        if (value != null && value.length > 0) {
            getFieldAdditionalData({
                url,
                params: params2
            })
                .then((resp) => {
                    if (resp?.data) {
                        setAutocompleteOptions(clearTextFromApiForOptionAutocomplete(resp.data));
                    } else {
                        setAutocompleteOptions([]);
                    }
                })
                .catch((_) => {});
        } else {
            setAutocompleteOptions([]);
        }
    };

    const handleAddNewObject = () => {
        window.open(generateAddBackUrl(dynamicFieldT, fieldSuperior));

        startAddBackBroadcastChannel((event: MessageEvent<BroadcastAddBackPayload>) => {
            setFieldValue({
                opcje: {
                    ...field.value.opcje,
                    [event.data.id]: event.data.name
                },
                value: event.data.id,
                text: event.data.name
            });
        });
    };

    React.useEffect(() => {
        if (field.value.text !== inputValue) {
            setInputValue(field.value.text);
        }
    }, [field.value.text]);

    React.useEffect(() => {
        setAutocompleteOptions(DynamicFormObjectDataConverter.getArrayIdAndText(field.value.opcje));
    }, [field.value.opcje]);

    return (
        <UIFieldListForeign
            onInputChangeAutocomplete={(_, value: string, reason) => {
                if (reason === 'input') {
                    if (field.value.value && value !== field.value.text) {
                        setFieldValue({
                            ...field.value,
                            value: '',
                            text: value
                        });
                    }
                    setInputValue(value);
                    getOptionsFromApi(value);
                }
            }}
            value={field.value}
            options={createSelectOptions({
                obj: field.value.opcje,
                type: dynamicFieldT.porzadek_elementow
            })}
            onChange={onChange}
            inputValueAutocomplete={inputValue ?? ''}
            uiFieldCommonProps={{
                ...getNeededFieldParamsToUiFieldCommonPropsFull(field),
                ...uiFieldCommonProps
            }}
            uiFieldSharedProps={{
                dynamicFieldT,
                inputProps: {
                    [dataSetInputKey]: keyOfFieldInState
                }
            }}
            onClearAutocomplete={() => {
                setInputValue('');
            }}
            onBlur={onBlur}
            optionsAutocomplete={autocompleteOptions}
            onChangeAutocomplete={onChangeAutocomplete}
            wyszukiwanie_rozwijalna={dynamicFieldT.wyszukiwanie_rozwijalna === 't' ? 't' : 'f'}
            autocompleteFilterOptions={(options, params) => {
                const filtered = filter(options, params);
                if (
                    dynamicFieldT.dodanie_nowego === 't' &&
                    !filtered.find((item) => item.id === 'nowy')
                ) {
                    filtered.push({ id: 'nowy', text: 'Dodaj...' });
                }

                return filtered;
            }}
        />
    );
};
