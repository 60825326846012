import React, { FC } from 'react';
import { useTheme } from '@mui/material/styles';

import { TextareaAutosize } from '@material-ui/core';
import { DynamicLabel } from 'src/components/shared/atoms/dynamicLabel/DynamicLabel';
import style from './style.module.css';
import { DynamicTekstWithoutRestrictProps } from 'src/components/shared/atoms/dynamicTextWithoutRestrict/type';

export const DynamicTextWithoutRestrict: FC<DynamicTekstWithoutRestrictProps> = ({
    field,
    dynamicFieldT,
    fieldValue
}) => {
    const theme = useTheme();

    return (
        <div className={style.wrapper}>
            <DynamicLabel label={dynamicFieldT.nazwa} />
            <TextareaAutosize
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                    field.onChange({
                        ...fieldValue,
                        value: e.target.value
                    });
                }}
                value={fieldValue.value}
                minRows={dynamicFieldT?.rozmiar ? dynamicFieldT.rozmiar : 2}
                style={{
                    width: '100%',
                    border: `solid 1px ${theme.palette.primary.main}`,
                    color: theme.palette.primary.main
                }}
            />
        </div>
    );
};
