import React, { FC, useMemo } from 'react';
import InputB from '@mui/material/InputBase';
import { useTheme } from '@mui/material/styles';

import { getColorForInputWithAllFlag } from 'src/styles/getColor';
import { sxInputV1Default } from 'src/components/shared/atoms/uiInputs/sxDefault';
import { UiInputNormalTwoProps } from 'src/components/shared/atoms/uiInputs/types';

export const UiInputNormalTwo: FC<UiInputNormalTwoProps> = (props) => {
    const {
        type = 'text',
        archived,
        disabled,
        disabledWew,
        value,
        maxlength,
        alignTextRight,
        inputProps
    } = props;
    const theme = useTheme();
    const sxinput = useMemo(
        () => ({
            border: `solid 1px ${getColorForInputWithAllFlag({
                theme,
                flag: {
                    archived: props.archived,
                    disabled: props.disabled,
                    isError: props.isError,
                    disabledWew: props.disabledWew
                }
            })}`,
            color: archived ? theme.palette.gray.second : theme.palette.primary.main,
            ...sxInputV1Default,
            ...props.sxinput
        }),
        [
            props.sxinput,
            props.archived,
            props.disabled,
            props.isError,
            props.disabledWew,
            archived,
            theme
        ]
    );

    const inputPropsForInputB = useMemo(
        () => ({
            ...inputProps,
            maxlength,
            style: (alignTextRight
                ? {
                      textAlign: 'right',
                      padding: '4px 4px 5px'
                  }
                : {
                      textAlign: 'left',
                      padding: '4px 0 5px'
                  }) as React.CSSProperties
        }),
        [inputProps, alignTextRight, maxlength]
    );

    return (
        <InputB
            sx={sxinput}
            inputProps={inputPropsForInputB}
            value={value}
            disabled={disabled ?? disabledWew}
            type={type}
            onChange={props.onChange}
            onBlur={props.onBlur}
            endAdornment={props.endAdornment}
            name={props.name}
            autoComplete="on"
        />
    );
};
