import React, { FC } from 'react';

import { useTypedSelector } from 'src/store';
import { PopupFormExtendedProps } from 'src/components/shared/molecules/actionType/popupFormExtended/types';
import { TemplateMainManager } from 'src/utils/src/shared/TemplateMainManager';
import { SourceOfTrigger } from 'src/data/sourceOfTrigger';
import { useOpenPopupFormExtended } from 'src/hooks';
import { SystemAction } from 'src/data/types';
import { ProcesedPopupFormExtendedObjectId } from 'src/data/popupFormTypes';

const PopupFormExtended: FC<PopupFormExtendedProps> = ({
    children,
    action,
    rowId,
    clickCallback
}) => {
    const rowData = useTypedSelector((state) => state.listScreenTableData.rowData);
    const transformationState = useTypedSelector((state) => state.listTransformations);
    const objectSlice = useTypedSelector((state) => state.object);
    const additional = { sectionId: '', sectionIdx: 0, typeSection: null };
    const sourceOfTrigger: SourceOfTrigger = { type: 'list' };

    const openPopupformExtended = useOpenPopupFormExtended();

    const handleSetPopupForm = async () => {
        if (action.behaviour.type !== 'popupFormExtended') {
            console.error('PopupFormExtended: action type is not valid');
            return;
        }
        const { str } = new TemplateMainManager({
            rowId,
            rowData,
            transformationState,
            actionBehavior: action.behaviour,
            action,
            objectSlice,
            sourceOfTrigger,
            additional,
            str: action.behaviour.data.objectId
        }).getData();

        const actionNew: SystemAction = {
            ...action,
            behaviour: {
                ...action.behaviour,
                data: {
                    ...action.behaviour.data,
                    objectId: str as ProcesedPopupFormExtendedObjectId
                }
            }
        };

        openPopupformExtended(actionNew);
        clickCallback && clickCallback();
    };

    return <div onClick={() => handleSetPopupForm()}>{children}</div>;
};

export default PopupFormExtended;
