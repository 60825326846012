import React from 'react';
import { LogoProps } from './types';
import { useNavigate } from 'react-router-dom';
import styles from './Logo.module.css';
import { routesPath } from 'src/data/routesPath';

//logo files are store in public/logo
const Logo = (props: LogoProps) => {
    const [logoImage, SetLogoImage] = React.useState();
    const navigate = useNavigate();
    React.useEffect(() => {
        const importImage = async () => {
            const { default: importedImage } = await import(`src/assets/logo/${props.name}`);
            SetLogoImage(importedImage);
        };
        importImage();
    }, []);

    const isSvg = (filename: string) => {
        return filename && filename.split('.').pop() === 'svg';
    };

    const handleOnClick = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        window.open(window.location.origin + routesPath.start.path, '_blank');
    };

    return (
        <div
            className={styles.wrapper}
            onClick={() => navigate(routesPath.start.path)}
            onContextMenu={handleOnClick}>
            {logoImage && (
                <div>
                    {isSvg(props.name) && <div>{logoImage}</div>}
                    {!isSvg(props.name) && (
                        <div>
                            <img
                                src={logoImage}
                                alt="Logo"
                                style={{ width: '100%', height: 'auto', objectFit: 'contain' }}
                            />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default Logo;
