import React, { FC } from 'react';

import { PasswordInputProps } from './types';
import { UiFieldLabel } from 'src/components/popupFormExtended/dynamicField/shared/uiFieldLabel/UiFieldLabel';
import { UiInputNormalTwo } from 'src/components/popupFormExtended/dynamicField/shared/uiInputNormalTwo/UiInputNormalTwo';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';

import { IconButton, InputAdornment } from '@mui/material';

export const PasswordInput: FC<PasswordInputProps> = ({
    value,
    sxinput,
    onChange,
    uiFieldCommonProps,
    uiFieldSharedProps,
    onBlur,
    datakey
}) => {
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };
    return (
        <>
            <UiFieldLabel {...uiFieldCommonProps} {...uiFieldSharedProps} />
            <UiInputNormalTwo
                value={value}
                type={showPassword ? 'text' : 'password'}
                sxinput={sxinput}
                onChange={onChange}
                onBlur={onBlur}
                inputProps={{
                    ...uiFieldSharedProps.inputProps,
                    'data-fieldkey-input': datakey,
                    className: 'react'
                }}
                endAdornment={
                    <InputAdornment position="end" tabIndex={-1}>
                        <IconButton
                            tabIndex={-1}
                            sx={{ marginRight: '0' }}
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end">
                            {showPassword ? (
                                <VisibilityOff fontSize="small" />
                            ) : (
                                <Visibility fontSize="small" />
                            )}
                        </IconButton>
                    </InputAdornment>
                }
            />
        </>
    );
};
