import React, { FC } from 'react';
import { CrossIconProps } from './types';
import styles from './styles.module.css';
import { useTheme } from '@mui/material/styles';

export const CrossIcon: FC<CrossIconProps> = ({ style }) => {
    const theme = useTheme();
    return (
        <div className={styles.close} style={style}>
            <div
                className={styles.closeOne}
                style={{
                    backgroundColor: theme.palette.primary.main
                }}></div>
            <div
                className={styles.closeTwo}
                style={{
                    backgroundColor: theme.palette.primary.main
                }}></div>
        </div>
    );
};
