import { Fields } from 'src/components/popupFormExtended/dynamicField/types';
import {
    JS_TIMESTAMP_DAY,
    JS_TIMESTAMP_MONTH,
    JS_TIMESTAMP_WEEK,
    JS_TIMESTAMP_YEAR,
    JS_TIMESTAMP_HOUR,
    JS_TIMESTAMP_MINUTE
} from 'src/constants/timestamps';
import { FieldDateRelativeBasicTypeValue } from 'src/data/fieldsFormType';
import { DynamicFieldT } from 'src/data/popupFormTypes';

const regexNumber = /[0-9]/g;
const regexDash = /[-]/g;
export class DateManager {
    static combineDateWithTime({ date, time }: { date: Date; time: Date }) {
        const minuts = new Date(time).getMinutes();
        const hours = new Date(time).getHours() * 60;
        const allMinutsTimestamp = (hours + minuts) * 60000;
        const allDayTimestamp = new Date(new Date(date).setHours(0, 0, 0, 0)).getTime();
        return new Date(allDayTimestamp + allMinutsTimestamp);
    }

    static getDaysInMonth(year: number, month: number) {
        return new Date(year, month, 0).getDate();
    }
    static formatedDateToNewDate(date: Date | string) {
        if (date instanceof Date) {
            return date;
        }
        const numbersArr = [...date.matchAll(regexNumber)];
        if (numbersArr.length !== 8) return new Date();
        const numbersStr = numbersArr
            .map((item) => item[0])
            .reduce((previousValue, currentValue) => previousValue + currentValue);

        const days = Number(numbersStr.slice(0, 2));
        const month = Number(numbersStr.slice(2, 4));
        const year = Number(numbersStr.slice(4, 8));
        return new Date(year, month - 1, days);
    }

    static prepareFromVariousTypeOfDateToNewDate(date?: Date | string) {
        if (date instanceof Date) return date;

        if (!date || typeof date !== 'string') return null;

        if (date.match(regexDash)) {
            const numbersArr = [...date.matchAll(regexNumber)];
            if (numbersArr.length !== 8) return new Date();

            const numbersStr = numbersArr
                .map((item) => item[0])
                .reduce((previousValue, currentValue) => previousValue + currentValue);

            const days = Number(numbersStr.slice(0, 2));
            const month = Number(numbersStr.slice(2, 4));
            const year = Number(numbersStr.slice(4, 8));
            return new Date(year, month - 1, days);
        }

        if (date.length === 10 && !Number.isNaN(Number(date)))
            return new Date(Number(date + '000'));
        return null;
    }

    static prepareFromVariousTypeOfDateToNewDateFromDynamicT(dynamicT: DynamicFieldT) {
        if (dynamicT.domyslna_wartosc && dynamicT.domyslna_wartosc.match(regexDash)) {
            const numbersArr = [...dynamicT.domyslna_wartosc.matchAll(regexNumber)];
            if (numbersArr.length === 8) {
                const numbersStr = numbersArr
                    .map((item) => item[0])
                    .reduce((previousValue, currentValue) => previousValue + currentValue);

                const days = Number(numbersStr.slice(0, 2));
                const month = Number(numbersStr.slice(2, 4));
                const year = Number(numbersStr.slice(4, 8));
                return new Date(year, month - 1, days);
            }
        }
        if (dynamicT.domyslna_dzisiejsza === 't') return new Date();
        return null;
    }

    static prepareStartOrEndDate(dynamicT: DynamicFieldT, type: 'rozp' | 'zak') {
        let apiDate = dynamicT.domyslna_wartosc_rozp_tekst
            ? dynamicT.domyslna_wartosc_rozp_tekst
            : dynamicT.domyslna_wartosc_rozp;
        if (type === 'zak') {
            apiDate = dynamicT.domyslna_wartosc_zak_tekst
                ? dynamicT.domyslna_wartosc_zak_tekst
                : dynamicT.domyslna_wartosc_zak;
        }
        let dataToReturn = '0:0';
        let tempHour = '0';
        let tempMinute = '0';
        if (!apiDate) return dataToReturn;
        const data = apiDate.split(':');
        if (Array.isArray(data) && data.length === 2) {
            if (data?.[0]?.[0] === '0') {
                tempHour = data[0].slice(1);
            } else tempHour = data[0];
            if (data?.[1]?.[0] === '0') {
                tempMinute = data[1].slice(1);
            } else tempMinute = data[1];
            dataToReturn = tempHour + ':' + tempMinute;
        }

        return dataToReturn;
    }

    static setTimeString(date: Date | string) {
        if (date instanceof Date) {
            let hours: string | number = date.getHours();
            if (hours < 10) hours = '0' + hours;
            let minutes: string | number = date?.getMinutes();
            if (minutes < 10) minutes = '0' + minutes;
            return `${hours}:${minutes}`;
        }
        return '00:00';
    }

    static setHourString(date: Date | string) {
        if (date instanceof Date) {
            let hours: string | number = date.getHours();
            if (hours < 10) hours = '0' + hours;
            return String(hours);
        }
        return '00';
    }

    static setMinutesString(date: Date | string) {
        if (date instanceof Date) {
            let minutes: string | number = date?.getMinutes();
            if (minutes < 10) minutes = '0' + minutes;
            return String(minutes);
        }
        return '00';
    }

    static setDateFromPhpTimestamp(timestampPhp: string) {
        const timestamp = Number(timestampPhp) * 1000;
        return new Date(timestamp);
    }
    static isToday(date: Date | string) {
        const today = new Date();
        if (date instanceof Date) {
            if (today.toDateString() === date.toDateString()) {
                return true;
            }
        } else if (typeof date === 'string') {
            const dateParts: string[] = date.split('-');
            const days = Number(dateParts[1]) - 1;
            const dateObject = new Date(Number(dateParts[2]), Number(days), Number(dateParts[0]));
            if (dateObject.toDateString() === today.toDateString()) {
                return true;
            }
        }
        return false;
    }

    static setTimestampFromDateMinuteHour({
        date,
        minute,
        hour
    }: {
        date: Date | number;
        minute: string | number;
        hour: string | number;
    }) {
        const mTimestamp = Number(minute) * 60 * 1000;
        const hTimestamp = Number(hour) * 60 * 60 * 1000;
        let timestamp = 0;
        if (typeof date === 'number') {
            timestamp = date;
        } else {
            timestamp = new Date(date).setHours(0, 0, 0);
        }
        return timestamp + hTimestamp + mTimestamp;
    }

    static setTimestampFromDateMinuteHour2({
        date,
        minute,
        hour
    }: {
        date: Date | number | null;
        minute: string | number | null;
        hour: string | number | null;
    }) {
        if (date) {
            const mTimestamp = Number(minute) * 60 * 1000;
            const hTimestamp = Number(hour) * 60 * 60 * 1000;
            const timestamp = new Date(date).setHours(0, 0, 0);
            return timestamp + hTimestamp + mTimestamp;
        }
        return 0;
    }

    static getAllTimestampFromField(field: Fields) {
        let date: number | null = null;
        switch (field?.typ) {
            case 'data':
                if (field.value) {
                    date = field.value.getTime();
                }
                break;
            case 'data_godzina':
                if (field.value.date && field.value.hour && field.value.minute) {
                    const timeStamp = DateManager.setTimestampFromDateMinuteHour2({
                        date: field.value.date,
                        hour: field.value.hour,
                        minute: field.value.minute
                    });
                    date = timeStamp;
                }
                break;
            default:
                break;
        }
        return date;
    }

    static setDateWithMinuteHour({ date }: { date: Date }) {
        return {
            date: date,
            minute: date.getMinutes(),
            hour: date.getHours()
        };
    }

    static setTimestamFromMinutes(minutes: string | number) {
        return Number(minutes) * 60 * 1000;
    }
    static setTimestamFromHours(hours: string | number) {
        return Number(hours) * 60 * 60 * 1000;
    }

    static setDateFromStrDDMMYYYY(str: string) {
        const data = str.split('-');
        if (Array.isArray(data) && data[0] && data[1] && data[2]) {
            return new Date(`${data[2]}-${data[1]}-${data[0]}`);
        }
        return null;
    }

    static getTimestampFromDateStr(str: string) {
        if (typeof str !== 'string') return 0;
        const dateArr = str.split('-');
        if (Array.isArray(dateArr) && dateArr[0] && dateArr[1] && dateArr[2]) {
            return new Date().setFullYear(
                Number(dateArr[2]),
                Number(dateArr[1]) - 1,
                Number(dateArr[0])
            );
        } else return 0;
    }

    static getTimestampFromDateStrWithoutHourMinutes(str: string) {
        if (typeof str !== 'string') return 0;
        const dateArr = str.split('-');
        if (Array.isArray(dateArr) && dateArr[0] && dateArr[1] && dateArr[2]) {
            const dateWithoutTime = new Date().setHours(0, 0, 0, 0);
            return new Date(dateWithoutTime).setFullYear(
                Number(dateArr[2]),
                Number(dateArr[1]) - 1,
                Number(dateArr[0])
            );
        } else return 0;
    }

    static getTimestampFromDateHourStr(str: string) {
        if (typeof str !== 'string') return 0;
        const arr = str.split(' ');
        if (!Array.isArray(arr) || typeof arr[0] !== 'string' || typeof arr[1] !== 'string')
            return 0;
        const hoursMinutes = arr[1].split(':');
        if (
            !Array.isArray(hoursMinutes) ||
            typeof hoursMinutes[0] !== 'string' ||
            typeof hoursMinutes[1] !== 'string'
        )
            return 0;
        const dateTimestamp = DateManager.getTimestampFromDateStr(arr[0]);

        const hoursTimestamp = Number(hoursMinutes[0]) * JS_TIMESTAMP_HOUR;
        const minutesTimestamp = Number(hoursMinutes[1]) * JS_TIMESTAMP_MINUTE;
        return dateTimestamp + hoursTimestamp + minutesTimestamp;
    }

    static changeMonthInDate(date: Date, num: number, sign: string) {
        if (date instanceof Date && !isNaN(num)) {
            switch (sign) {
                case '+': {
                    const m = date.getMonth() + num;
                    const d = date.getDate();
                    const y = date.getFullYear();
                    const newDate = new Date(y, 0, d);
                    const timestamp = newDate.setMonth(m);
                    return new Date(timestamp);
                }
                case '-': {
                    const m = date.getMonth() - num;
                    const d = date.getDate();
                    const y = date.getFullYear();
                    const newDate = new Date(y, 0, d);
                    const timestamp = newDate.setMonth(m);
                    return new Date(timestamp);
                }
                default:
                    return date;
            }
        }
        return null;
    }

    static changeYearInDate(date: Date, num: number, sign: string) {
        if (date instanceof Date && !isNaN(num)) {
            switch (sign) {
                case '+': {
                    const m = date.getMonth();
                    const d = date.getDate();
                    const y = date.getFullYear() + num;
                    const newDate = new Date(1970, m, d);
                    const timestamp = newDate.setFullYear(y);
                    return new Date(timestamp);
                }
                case '-': {
                    const m = date.getMonth();
                    const d = date.getDate();
                    const y = date.getFullYear() - num;
                    const newDate = new Date(1970, m, d);
                    const timestamp = newDate.setFullYear(y);
                    return new Date(timestamp);
                }
                default:
                    return date;
            }
        }
        return null;
    }

    static getMinutesAndHours(time: string) {
        const data = time.split(':');
        const minutes = data[1] ? data[1] : 0;
        const hours = data[0] ? data[0] : 0;
        return [Number(hours), Number(minutes)];
    }

    static getTimestampFromDateRelative(value: FieldDateRelativeBasicTypeValue) {
        if (value.date && !isNaN(Number(value.date))) {
            switch (value.zakres) {
                case 'd':
                    return Number(value.date) * JS_TIMESTAMP_DAY;
                case 't':
                    return Number(value.date) * JS_TIMESTAMP_WEEK;
                case 'm':
                    return Number(value.date) * JS_TIMESTAMP_MONTH;
                case 'r':
                    return Number(value.date) * JS_TIMESTAMP_YEAR;
                default:
                    return null;
            }
        }
        return null;
    }
    static getDateDayDashMonthDashYear(value: Date | null) {
        if (value instanceof Date) {
            const date = new Date(value).toLocaleDateString('pl');
            return date.replaceAll('.', '-');
        }
        return value;
    }

    static prepareDayMonthYearFromDate(value: Date | null) {
        if (value instanceof Date) {
            let day: string | number = value?.getDate();
            if (day < 10) day = '0' + day;
            let month: string | number = value?.getMonth() + 1;
            if (month < 10) month = '0' + month;
            const year = value?.getFullYear();
            return `${day}-${month}-${year}`;
        }
        return value;
    }
    static prepareHourMinutesFromStringWithoutZero(value: string) {
        if (typeof value === 'string') {
            const hour = Array.from(value);
            if (hour?.[0] === '0') {
                return value.slice(1);
            }
        }
        return value;
    }
    static getHourAndMinuteFromTimestamp(timestamp: number | string) {
        let data = '00:00';
        if (typeof timestamp === 'string' || typeof timestamp === 'number') {
            const length = String(timestamp).length;
            let timestampNew = timestamp;
            if (length === 10) {
                timestampNew = timestamp + '000';
            }
            let hours = String(new Date(Number(timestampNew)).getHours());
            let minutes = String(new Date(Number(timestampNew)).getMinutes());
            data = `${hours}:${minutes}`;
        }

        return data;
    }
}
