import React, { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useAppDispatch, AppDispatch, useTypedSelector } from 'src/store';
import { PopupBehavior, InternalBehavior } from 'src/data/types';
import { general, globalPopup } from 'src/constants/subtitles';
import { PopupManager } from 'src/store/src/popup/popup/PopupManager';
import { ubdateData } from 'src/store/src/listScreen/treeData/treeDataSlice';
import { TreeDataManager } from 'src/store/src/listScreen/treeData/TreeDataManager';
import ApiAction from './apiAction/ApiAction';
import PopupForm from './popupForm/PopupForm';
import FileDownload from './fileDownload/FiledDownload';
import Help from './help/Help';
import { generateToken, getLinkCode } from 'src/utils';
import NavigateAction from './navigateAction/NavigateAction';
import SearchFilterAction from './searchFilterAction/SearchFilterAction';
import { LinkAction } from 'src/components/shared/molecules/actionType/linkAction/LinkAction';
import PopupFormExtended from 'src/components/shared/molecules/actionType/popupFormExtended/PopupFormExtended';
import { ActionTypeProps } from 'src/components/shared/molecules/actionType/types';
import { EventBus } from 'src/utils/src/shared/EventBus';
import ApiActionSaveConfig from 'src/components/shared/molecules/actionType/apiActionSaveConfig/ApiActionSaveConfig';
import ApiActionSendMessage from 'src/components/shared/molecules/actionType/apiActionSendMessage/ApiActionSendMessage';
import ApiActionSaveMassEditObjects from 'src/components/shared/molecules/actionType/apiActionSaveMassEditObjects/ApiActionSaveMassEditObjects';
import ApiActionRejectSaveMassEditObjects from 'src/components/shared/molecules/actionType/apiActionRejectSaveMassEditObjects/ApiActionRejectSaveMassEditObjects';
import ApiActionWithActualMonth from 'src/components/shared/molecules/actionType/apiActionWithActualMonth/ApiActionWithActualMonth';
import { UtilityBarCrossIcon } from 'src/components/shared/molecules/utilityBarCrossIcon/UitilityBarCrossIcon';
import ApiActionImportPayoff from 'src/components/shared/molecules/actionType/apiActionImportPayoff/ApiActionImportPayoff';

// DOC

// ActionType w skali całego programu(poza ekranem obiektu) jest plikiem rozdzielającym w formie switcha możliwe do wykonania akcje na postawie parametru action.behaviour.type
// ActionType in global scale is file which divide (in form swtich) possible action base on parmam action behaviour.type

export const ActionType: FC<ActionTypeProps> = (props: ActionTypeProps) => {
    const { action, children, rowId, clickCallback } = props;
    const dispatch: AppDispatch = useAppDispatch();
    const treeDataState = useTypedSelector((state) => state.listTreeTableData);
    const systemURL = useTypedSelector((state) => state.system.systemUrl);
    const location = useLocation();
    const navigate = useNavigate();

    const handleSetPopupTemplate = (behaviour: PopupBehavior) => {
        new PopupManager({ dispatch, action }).setPopupTemplate({
            behaviour,
            rowId,
            sourceOfTrigger: {
                type: 'list'
            }
        });

        clickCallback && clickCallback();
    };

    const handleGetLink = async () => {
        const code = await getLinkCode(location.state, location.pathname);

        if (!code) {
            clickCallback && clickCallback();
            return;
        }

        const closeCallbackEventId = `copyLinkAction-close-${generateToken()}`;

        EventBus.$on(closeCallbackEventId, () => {
            EventBus.$off(closeCallbackEventId);

            clickCallback && clickCallback();
        });

        new PopupManager({ dispatch, action: null }).setPopupPrompt({
            title: globalPopup.contentPrompt.title,
            message: general.getLinkPrompt,
            focusText: true,
            placeholder: `${systemURL}link?code=${code}`,
            closeCallbackEventId
        });

        clickCallback && clickCallback();
    };

    const handleInternal = (behaviour: InternalBehavior) => {
        switch (behaviour.data?.type) {
            case 'toggleUnfoldNodes':
                dispatch(
                    ubdateData(
                        new TreeDataManager({
                            treeNodeUbdated: treeDataState.treeNodeUbdated,
                            setting: treeDataState.setting
                        }).toggleThirdLevelChildrenUnfold()
                    )
                );
                break;
            case 'changeView':
                if (behaviour.data.data) {
                    const params = new URLSearchParams(window.location.search);
                    navigate(`/lista-${behaviour.data.data}?${params}`, {
                        state: location.state
                    });
                } else {
                    const params = new URLSearchParams(window.location.search);
                    navigate(`/lista?${params}`, {
                        state: location.state
                    });
                }
                break;
            default:
                break;
        }

        clickCallback && clickCallback();
    };

    switch (action.behaviour.type) {
        case 'apiAction':
            switch (action.code) {
                case 'massEditObjectsSave':
                    return (
                        <ApiActionSaveMassEditObjects {...props}>
                            {children}
                        </ApiActionSaveMassEditObjects>
                    );
                case 'massEditObjectsRejectSave':
                    return (
                        <ApiActionRejectSaveMassEditObjects {...props}>
                            <UtilityBarCrossIcon name={'Odrzuć zmiany'} />
                        </ApiActionRejectSaveMassEditObjects>
                    );
                case 'configSave':
                    return <ApiActionSaveConfig {...props}>{children}</ApiActionSaveConfig>;
                case 'sendMessage':
                    return <ApiActionSendMessage {...props}>{children}</ApiActionSendMessage>;
                case 'withActualMonth':
                    return (
                        <ApiActionWithActualMonth {...props}>{children}</ApiActionWithActualMonth>
                    );
                case 'importpayoff':
                    return <ApiActionImportPayoff {...props}>{children}</ApiActionImportPayoff>;
                default:
                    return <ApiAction {...props}>{children}</ApiAction>;
            }
        case 'popupForm':
            return <PopupForm {...props}>{children}</PopupForm>;
        case 'getLink':
            return <div onClick={handleGetLink}>{children}</div>;
        case 'popup':
            return (
                <div onClick={() => handleSetPopupTemplate(action.behaviour as PopupBehavior)}>
                    {children}
                </div>
            );
        case 'popupFormExtended':
            return <PopupFormExtended {...props}>{children}</PopupFormExtended>;
        case 'fileDownload':
            return <FileDownload {...props}>{children}</FileDownload>;
        case 'navigate':
            return (
                <NavigateAction {...props} behaviour={action.behaviour}>
                    {children}
                </NavigateAction>
            );
        case 'link':
            return <LinkAction {...props}>{children}</LinkAction>;
        case 'internal':
            return (
                <div onClick={() => handleInternal(action.behaviour as InternalBehavior)}>
                    {children}
                </div>
            );
        case 'searchFilter':
            return (
                <SearchFilterAction {...props} behaviour={action.behaviour}>
                    {children}
                </SearchFilterAction>
            );
        case 'help':
            return (
                <Help {...props}>
                    <div>{children}</div>
                </Help>
            );
        case 'addTimer':
            return (
                <div
                    onClick={() => {
                        window.dispatchEvent(
                            new CustomEvent('create_timer', {
                                detail: {
                                    typ_czego: action.behaviour.data.type,
                                    id_czego: action.behaviour.data.id,
                                    nazwa_czego: action.behaviour.data.name
                                }
                            })
                        );
                        clickCallback && clickCallback();
                    }}>
                    {children}
                </div>
            );
        default:
            return <div>{children}</div>;
    }
};
