import * as actionTypes from './actionTypes';
import { mergeTask } from './mergeTask';

const initialState = {
    tasks: [],
    events: [],
    open: false,
    allLoaded: false,
    page: 1
};

export default (state = initialState, action) => {
    const { payload } = action;
    switch (action.type) {
        case actionTypes.SET_EVENTS: {
            const events = payload.data;
            return {
                ...state,
                events: events || []
            };
        }

        case actionTypes.LOAD_TASKS: {
            console.log(
                'LOAD_TASKS CORRECT: state:',
                structuredClone(mergeTask(state.tasks, payload.data || []))
            );
            const tasks = payload.data;
            console.log(
                'LOAD_TASKS NOT CORRECT: state:',
                structuredClone(state.tasks.concat(tasks || []))
            );
            return {
                ...state,
                tasks: mergeTask(state.tasks, payload.data || []),
                page: state.page + 1
            };
        }
        case actionTypes.TOGGLE_OPEN:
            return {
                ...state,
                open: !state.open
            };

        case actionTypes.SET_ALL_LOADED:
            return {
                ...state,
                allLoaded: true
            };

        case actionTypes.REFRESH_LOAD_STATE:
            return {
                ...state,
                allLoaded: false,
                page: 1,
                tasks: []
            };

        default:
            return state;
    }
};
