import React, { FC } from 'react';
import { useAppDispatch, AppDispatch, useTypedSelector } from 'src/store';
import { PopupManager } from 'src/store/src/popup/popup/PopupManager';
import { TableDataManager } from 'src/store/src/listScreen/tableData/TableDataManager';
import { PopupFormBehavior } from 'src/data/popupFormTypes';
import { PopupFormProps } from 'src/components/shared/molecules/actionType/popupForm/types';
import { setSpinner } from 'src/store/src/general/system/systemSlice';
import { useLoadPopupFormFields } from 'src/hooks/src/shared/useLoadPopupFormFields';

const PopupForm: FC<PopupFormProps> = ({
    children,
    action,
    rowId,
    clickCallback,
    sourceOfTrigger
}) => {
    const dispatch: AppDispatch = useAppDispatch();
    const behaviour = action.behaviour as PopupFormBehavior;
    const rowData = useTypedSelector((state) => state.listScreenTableData.rowData);

    const loadPopupFormData = useLoadPopupFormFields();

    const handleSetPopupForm = async () => {
        const rowsId = rowId
            ? [rowId]
            : new TableDataManager({
                  rowData,
                  action,
                  rowId
              }).getFilteredRowsId();
        if (sourceOfTrigger && sourceOfTrigger.type === 'addObjectShortcuts') {
            const response = await loadPopupFormData(behaviour.data.actionId);
            if (!response.success) return;

            new PopupManager({ dispatch, action }).setPopupDynamic({
                rowsId: ['nowy'],
                title: behaviour.data.title,
                sourceOfTrigger,
                popupFormData: {
                    ...behaviour.data,
                    fields: response.data
                }
            });
        } else if (sourceOfTrigger && sourceOfTrigger.type === 'startupCardList') {
            const response = await loadPopupFormData(behaviour.data.actionId);
            if (!response.success) return;
            new PopupManager({ dispatch, action }).setPopupDynamic({
                rowsId: rowsId,
                title: behaviour.data.title,
                sourceOfTrigger,
                popupFormData: {
                    ...behaviour.data,
                    fields: response.data
                }
            });
        } else if (rowsId?.length) {
            const response = await loadPopupFormData(behaviour.data.actionId);
            if (!response.success) return;

            new PopupManager({ dispatch, action }).setPopupDynamic({
                rowsId: rowsId,
                title: behaviour.data.title,
                sourceOfTrigger: {
                    type: 'list'
                },
                popupFormData: {
                    ...behaviour.data,
                    fields: response.data
                }
            });
        } else {
            new PopupManager({ dispatch, action }).setPopupInfo(
                'Brak wierszy spełniajacych kryteria'
            );
        }

        dispatch(setSpinner(false));
        clickCallback && clickCallback();
    };

    return <div onClick={() => handleSetPopupForm()}>{children}</div>;
};

export default PopupForm;
