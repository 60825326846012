import React, { FC } from 'react';
import { Autocomplete, InputBase, createFilterOptions } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import ButtonCross from 'src/components/shared/atoms/buttonCross/ButtonCross';
import style from './style.module.css';
import { useFieldClasses } from 'src/hooks';
import { generateToken, getTypeUrl } from 'src/utils';
import { getFieldAdditionalData } from 'src/api/src/shared/getFieldAdditionalData';
import { UiInputSelectTwo } from 'src/components/popupFormExtended/dynamicField/shared/uiInputSelectTwo/UiInputSelectTwo';
import { DynamicListForeignProps } from 'src/components/shared/atoms/dynamicListForeign/types';
import { DynamicLabel } from 'src/components/shared/atoms/dynamicLabel/DynamicLabel';
import { BroadcastAddBackPayload } from 'src/components/popupFormExtended/types';
import { FieldListForeignAutocompleteOption } from 'src/components/popupFormExtended/dynamicField/fieldListForeign/types';
import { useAddBackBroadcastChannel } from 'src/hooks/src/popupFormExtended/useAddBackBroadcastChannel';
import { generateAddBackUrlTwo } from 'src/utils/src/popupFormExtended/generateAddBackUrlTwo';

const filter = createFilterOptions<FieldListForeignAutocompleteOption>();

export const DynamicListForeign: FC<DynamicListForeignProps> = ({
    dynamicFieldT,
    field,
    fieldValue
}) => {
    const value = fieldValue.value;
    const theme = useTheme();
    const classes = useFieldClasses({ muiType: 'autocompleteOne' });
    const [inputValue, setInputValue] = React.useState(value?.text ?? '');

    const startAddBackBroadcastChannel = useAddBackBroadcastChannel(dynamicFieldT.kod);

    const [autocompleteOptions, setAutocompleteOptions] = React.useState([]);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        field.onChange({
            ...fieldValue,
            value: {
                ...fieldValue.value,
                value: e.target.value,
                text: fieldValue.value.opcje[e.target.value]
            }
        });
    };

    const getOptionsFromApi = (value: string) => {
        let valueSuperior: string | null = '';
        let params = {};
        let params2 = {};
        if (dynamicFieldT.pole_nadrzedne_typ) {
            params =
                dynamicFieldT.pole_nadrzedne_typ === 'lista_obca'
                    ? {
                          q: valueSuperior,
                          typ: dynamicFieldT.pole_nadrzedne_tabela_opcji,
                          kod: dynamicFieldT.tabela_opcji,
                          pole: dynamicFieldT.id,
                          filtr: value
                      }
                    : {
                          q: valueSuperior,
                          typ: dynamicFieldT.pole_nadrzedne_typ,
                          kod: dynamicFieldT.tabela_opcji,
                          pole: dynamicFieldT.id,
                          filtr: value
                      };
            params2 =
                dynamicFieldT.pole_nadrzedne_typ === 'lista_obca'
                    ? {
                          q: valueSuperior,
                          typ_nadrzedne: dynamicFieldT.pole_nadrzedne_tabela_opcji,
                          typ_podrzedne: dynamicFieldT.tabela_opcji,
                          id_pola: dynamicFieldT.id,
                          filtr: value
                      }
                    : {
                          q: valueSuperior,
                          typ_nadrzedne: dynamicFieldT.pole_nadrzedne_typ,
                          typ_podrzedne: dynamicFieldT.tabela_opcji,
                          id_pola: dynamicFieldT.id,
                          filtr: value
                      };
        } else {
            params = {
                q: value,
                typ: dynamicFieldT.tabela_opcji,
                pole: dynamicFieldT.id
            };
            params2 = {
                q: value,
                typ: dynamicFieldT.tabela_opcji,
                id_pola: dynamicFieldT.id
            };
        }
        const url = getTypeUrl({ field: dynamicFieldT });

        if (value != null && value.length > 0) {
            getFieldAdditionalData({
                url,
                params: params2
            })
                .then((resp) => {
                    if (resp?.data) {
                        setAutocompleteOptions(resp.data);
                    } else {
                        setAutocompleteOptions([]);
                    }
                })
                .catch((err) => {});
        } else {
            setAutocompleteOptions([]);
        }
    };

    const handleAddNewObject = () => {
        window.open(generateAddBackUrlTwo(dynamicFieldT.kod, dynamicFieldT.tabela_opcji ?? ''));

        startAddBackBroadcastChannel((event: MessageEvent<BroadcastAddBackPayload>) => {
            field.onChange({
                ...fieldValue,
                value: {
                    ...fieldValue.value,
                    value: event.data.id,
                    text: event.data.name
                }
            });
        });
    };
    return (
        <div className={style.wrapper}>
            <DynamicLabel label={dynamicFieldT.nazwa} />
            <div className={style.box}>
                {dynamicFieldT.wyszukiwanie_rozwijalna === 't' ? (
                    <Autocomplete
                        freeSolo
                        onInputChange={(_, value: string) => {
                            setInputValue(value);
                            getOptionsFromApi(value);
                        }}
                        options={autocompleteOptions || null}
                        value={{
                            id: value.value,
                            text: inputValue
                        }}
                        inputValue={inputValue}
                        getOptionLabel={(option: any) => {
                            return option?.text;
                        }}
                        renderOption={(props, option: any) => {
                            return (
                                <li {...props} key={option?.id}>
                                    {option?.text}
                                </li>
                            );
                        }}
                        disablePortal={true}
                        onChange={(
                            _: React.SyntheticEvent<Element, Event>,
                            newValue: { id: string; text: string } | null | string
                        ) => {
                            if (newValue && typeof newValue !== 'string') {
                                if (newValue.id === 'nowy') {
                                    handleAddNewObject();
                                    return;
                                }
                                field.onChange({
                                    ...field?.value,
                                    value: {
                                        ...value,
                                        value: newValue.id,
                                        text: newValue.text
                                    }
                                });
                            } else {
                                field.onChange({
                                    ...field?.value,
                                    value: {
                                        ...value,
                                        value: null,
                                        text: ''
                                    }
                                });
                            }
                        }}
                        clearIcon={
                            inputValue ? (
                                <ButtonCross
                                    onClick={() => {
                                        field.onChange({
                                            ...field?.value,
                                            value: {
                                                ...value,
                                                value: null,
                                                text: ''
                                            }
                                        });
                                    }}
                                    style={{
                                        top: '2px'
                                    }}
                                />
                            ) : (
                                <></>
                            )
                        }
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);
                            if (
                                dynamicFieldT.dodanie_nowego === 't' &&
                                !filtered.find((item) => item.id === 'nowy')
                            ) {
                                filtered.push({ id: 'nowy', text: 'Dodaj...' });
                            }
                            return filtered;
                        }}
                        classes={{
                            option: classes.option,
                            listbox: classes.listbox,
                            paper: classes.paper,
                            popper: classes.popper
                        }}
                        renderInput={(params: any) => {
                            return (
                                <InputBase
                                    {...params.InputProps}
                                    {...params}
                                    sx={{
                                        border: `solid 1px ${theme.palette.primary.main}`,
                                        color: theme.palette.primary.main,
                                        borderRadius: '4px',
                                        paddingLeft: '10px'
                                    }}
                                />
                            );
                        }}
                    />
                ) : (
                    <UiInputSelectTwo value={value.value} opcje={value.opcje} onChange={onChange} />
                )}
            </div>
        </div>
    );
};
